body {
    .ui-inputtext, .ui-inputtext-dark {
        background: transparent;
        border-width: 0 0 1px 0;  
        padding: 2px 2px 1px 2px;
        font-size: $inputFontSize;
        border-color: $zcBlack;
        border-style: solid;
        @include transition(border-color .3s);
        @include border-radius(0px);
        
        &:focus,
        &.ui-state-focus {
            border-width: 0 0 2px 0;
            border-color: $primaryColor;  
            padding-bottom: 0px;
        }
        
        &:disabled {
            border-bottom: 1px dotted;
        }
        
        &.ui-widget-content {
            border-width: 1px;
        }
        
        &.ui-state-error {
            border-color: #e62a10;  
        }
    }

    .ui-inputtext-dark {
        color: $zcGhost;
        border-color: $zcSlate;
    }

    .md-inputfield, .md-inputfield-dark {
        display: block;
        position:relative; 
        
        label {
          color: $zcSlate; 
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 2px;
          top: 1px;
          @include transition(all 0.3s ease);
        }
        
        input:focus ~ label,
        input.ui-state-filled ~ label,
        .ui-inputwrapper-focus ~ label,
        .ui-inputwrapper-filled ~ label {
          top:-16px;
          font-size:12px; 
        }

        input:focus ~ label,
        .ui-inputwrapper-focus ~ label {
            color: $primaryColor;
        }

        .input:-webkit-autofill ~ label {
          top: -20px;
          font-size: 12px;
          color: $primaryColor;
        }

        input.ng-dirty.ng-invalid ~ label {
            color: $zcCrit;
        }
        
        .ui-message {
            border: 0 none;
            color: $zcSlate;
            font-size: .75em;
            background-color: transparent;
            margin: 0px;

            &.ui-messages-error {
                color: $zcCrit;
            }
        }
    }

    .md-inputfield-dark {

        .ui-inputtext {
            color: $zcGhost;
            border-color: $zcSlate;
        }
        
    }

    .ui-material-message {
        &.ui-message {
            &.ui-messages-error {
                background-color: transparent;
                border: 0 none;
                margin: 0px;
                color: #e62a10;
                font-size: .75em;
            }
        }
    }
    
    .ui-listbox {
        min-width: 12.5em;
        background-color: #ffffff;
        @include border-radius($borderRadius);
        
        &.ui-inputtext {
            padding: 0;
        }
        
        .ui-listbox-list {
            padding: 0;
        }
        
        .ui-listbox-item {
            overflow: hidden;
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            margin: 0;
            color: $textColor;
            @include rippleitem();
            @include transition(background-color .3s);
            @include border-radius(0);       
                        
            &.ui-state-highlight {
                background: $zcWhite;
                color: $zcBlue;
                font-weight: 700;
            }

            &:hover {
                background: $zcPowder;
            }
        }
        
        .ui-listbox-header {
            @include border-radius(0);
            padding: $inputHeaderPadding;
            background: $zcWhite;
            border: 0;
            border-bottom: 1px solid $zcSilver;
            
            .ui-chkbox {
                .ui-chkbox-box {
                    border-color: #ffffff;
                }
            }
            
            .ui-listbox-filter-container {                
                .ui-inputtext {
                    color: #ffffff;
                    padding: 2px 2px 1px 2px;
                    padding-left: 1.75em;
                    border: 0;
                    color: $zcGray;

                    &:focus {
                        padding-bottom: 0;
                    }
                }
                
                .fa {
                    top: 0;
                    left: 0;
                    color: $zcGray;
                }
            }
        }
    }
        
    .ui-button, .ui-datepicker-buttonpane > button {
        overflow: hidden;
        background-color: $primaryColor;
        color: #ffffff;
        font-size: $buttonFontSize;
        height: 2.25em;
        padding: 0 1em;
        border: 0 none;
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        @include transition(background-color .3s);
        
        &:enabled:not(:focus):hover {
            background-color: $zcRoyal;   
        }
        
        &:focus {
            outline: 0 none;
            background-color: $zcRoyal;
        }
        
        .ui-button-text {
            padding: 0;
            line-height: auto;
        }
        
        .fa {
            color: #ffffff;
        }

        &.ui-button-icon-only {
            @include border-radius(0px);
            width: 2.5em;
            height: 2.5em;
            line-height: 0em;
            
            .fa {
                margin-left: -.5em;
            }
        }
                        
        &.ui-button-text-icon-left {
            padding-left: 2.5em;
        }
        
        &.ui-button-text-icon-right {
            padding-right: 2.5em;
        }
                        
        &.secondary {
            background-color: $zcWhite;
            color: $zcBlack;
            
            &:enabled:not(:focus):hover {
                background-color: $zcSmoke;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: $zcGhost;
            }
        }
        
        &.blue-grey-btn {
            background-color: #607D8B;
            
           &:enabled:not(:focus):hover {
                background-color: #37474F;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#607D8B,10%);
            }
        }
        
        &.cyan-btn {
            background-color: #00BCD4;
            
            &:enabled:not(:focus):hover {
                background-color: #00838F;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#00BCD4,10%);
            }
        }
        
        &.teal-btn {
            background-color: #009688;
            
            &:enabled:not(:focus):hover {
                background-color: #00695C;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#009688,10%);
            }
        }
        
        &.red-btn {
            background-color: #F44336;
            
            &:enabled:not(:focus):hover {
                background-color: #C62828;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#F44336,10%);
            }
        }
        
        &.green-btn {
            background-color: #4CAF50;
            
           &:enabled:not(:focus):hover {
                background-color: #2E7D32;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#4CAF50,10%);
            }
        }
        
        &.deep-orange-btn {
            background-color: #FF5722;
            
            &:enabled:not(:focus):hover {
                background-color: #D84315;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#FF5722,10%);
            }
        }
        
        &.purple-btn {
            background-color: #673AB7;
            
            &:enabled:not(:focus):hover {
                background-color: #4527A0;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#673AB7,10%);
            }
        }
        
        &.pink-btn {
            background-color: #E91E63;
            
            &:enabled:not(:focus):hover {
                background-color: #AD1457;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#E91E63,10%);
            }
        }
        
        &.amber-btn {
            background-color: #FFC107;
            color: #212121;
            
            &:enabled:not(:focus):hover {
                background-color: #FF8F00;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#FFC107,10%);
            }
        }
        
        &.orange-btn {
            background-color: #FF9800;
            
            &:enabled:not(:focus):hover {
                background-color: #EF6C00;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#FF9800,10%);
            }
        }
        
        &.brown-btn {
            background-color: #795548;
            
            &:enabled:not(:focus):hover {
                background-color: #4E342E;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#795548,10%);
            }
        }

        &.indigo-btn {
            background-color: #3F51B5;
            
            &:enabled:not(:focus):hover {
                background-color: #283593;   
            }
            
            &:focus {
                outline: 0 none;
                background-color: lighten(#3F51B5,10%);
            }
        }
        
        &.flat {
            @include no-shadow();
        }
    }

    .stroke {
        background-color: transparent;
        border: 1px solid $zcBlack;
        @include no-shadow();
        @include transition(all 0.3s);

        &:hover {
            background-color: transparent !important;
            border-color: $primaryColor;

            .fa {
                color: $primaryColor;
                @include transition(color 0.3s);
            }
        }

        &:focus {
            outline: 0 none;
            border-color: $primaryColor;
            background-color: $primaryColor !important;

            .fa {
                color: $zcWhite !important;
            }
        }

        &.ui-state-active {
            border-color: $primaryColor;
            background-color: $primaryColor;

            .fa {
                color: $zcWhite !important;
            }
        }

        .fa {
            color: $zcBlack;
        }
    }
    
    .ui-buttonset {
        .ui-button {
            &:not(.ui-state-disabled):not(.ui-state-active):hover {
                background-color: $zcRoyal;
            }

            &.ui-state-active {
                background-color: $zcBlack;
                color: $accentTextColor;
            }
        }
    }
    
    .ui-togglebutton {
        background: $zcWhite;
        color: $zcBlack;
        padding: 0.4em 1em;

        &:not(.ui-state-active):not(.ui-state-disabled):hover {
            background-color: $zcSmoke;
        }

        &.ui-state-active {
            background-color: $zcBlue;
            color: $accentTextColor;

            &:hover {
                background-color: $zcRoyal;
            }

        }
    }
    
    .ui-splitbutton {
        @include border-radius($borderRadius + 1);
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);

        > .ui-button {
            @include no-shadow();
            
            &:active {
                background-color: lighten($primaryColor,10%);
            }
        }
        
        .ui-splitbutton-menubutton {
            height: 2.25em;
            @include border-radius-left(0);
            @include border-radius-right($borderRadius);
        }
    }
    
    .ui-fluid {
        .ui-splitbutton {
            .ui-button:first-child {
                width: calc(100% - 2em);
            }
        }
    }
        
    .ui-chkbox, .ui-chkbox-dark {
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
        width: 20px;
        height: 20px;
        cursor: default;
        
        .ui-chkbox-box {
            border: 1px solid $zcGray;
            background-color: $zcSmoke;
            width: 20px;
            height: 20px;
            @include transition(background-color .3s);
            
            .ui-chkbox-icon {
                font-size: 18px;
                margin-left: 0px;
                margin-top: 1px;
            }
            
            &.ui-state-active {
                border-color: $primaryColor;
                background-color: $primaryColor;

                &.ui-state-focus {
                    border-color: $primaryColor;
                }
            }
            
            &.ui-state-focus {
                border-color: $zcGray;
                @include content-shadow();
                @include transition(box-shadow .3s,background-color .3s);
            }
        }
    }

    .ui-chkbox-dark {
        width: auto;
        height: auto;

            .ui-chkbox-label {
                color: $zcGhost;
            }

    }
    
    .ui-radiobutton, .ui-radiobutton-dark {
        position: relative;
        margin: 0 .25em 0 0;
        vertical-align: middle;
        
        .ui-radiobutton-box {
            border: 2px solid $zcGray;
            width: 20px;
            height: 20px;
            @include transition(box-shadow .3s);
            @include border-radius(50%);
            
            &.ui-state-focus {
                border-color: $primaryColor;
                @include content-shadow();
                @include transition(box-shadow .3s,background-color .3s);
            }
            
            &.ui-state-active {
                border-color: $primaryColor;
                background-color: transparent;
            }
    
            .ui-radiobutton-icon {
                display: block;
                box-sizing: border-box;
                @include border-radius(50%);
                font-size: 16px;
                transition: -webkit-transform ease .28s;
                transition: transform ease .28s;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            
            .fa-circle {
                background-color: $primaryColor;
                -webkit-transform: scale(0.5);
                transform: scale(0.5);
                position: relative;
                top: 1px;
            }
        }
    }

    .ui-radiobutton-dark {
        .ui-radiobutton-box {
            border: 2px solid $zcSlate;
        }
        
        .ui-radiobutton-label {
            color: $zcGhost;
        }
    }
        
    .ui-autocomplete-panel {
        @include border-radius(0);
        
        &.ui-shadow {
            @include overlay-input-shadow();
        }
        
        .ui-autocomplete-list {
            padding: 0;
            
            .ui-autocomplete-list-item {
                @include transition(background-color .3s);
                font-size: $inputOptionFontSize;
                padding: $inputOptionPadding;
                @include border-radius(0);
                color: $textColor;
                
                .ui-autocomplete-query {
                    font-weight: 700;
                }
                
                &.ui-state-highlight {
                    background: $zcPowder;
                }
            }
            
            .ui-autocomplete-group {
                padding: $inputOptionPadding;
            }
        }
    }
    
    .ui-autocomplete {        
        .ui-autocomplete-dropdown {
            &.ui-button.ui-button-icon-only {
                background-color: transparent;
                @include no-shadow();
                height: 1.5em;
                width: 1.5em;
                padding: 0;
                right: 0;
                bottom: 8px;
                top: auto;
                margin-right: 0;
                position: absolute;
                
                .ui-button-text {
                    display: none;
                }
                
                .fa {
                    color: $textSecondaryColor;
                }
            }
        }
        
        &.ui-autocomplete-multiple {
            .ui-autocomplete-multiple-container {
                &.ui-inputtext {
                    box-sizing: border-box;
                    padding: 2px 2px 1px 2px;
                }
                
                &.ui-state-focus {
                    padding-bottom: 0;
                    border-bottom: 2px solid $zcBlue;
                }
                
                &.ui-state-disabled {
                    border-bottom: 1px dotted;
                }
            }
            
            .ui-autocomplete-input-token {
                float: none;
                display: inline-block;
                margin: 0 1px;
                vertical-align: middle;
                padding: 2px 2px 1px 2px;
                
                > input {
                    padding: 0;
                    font-size: $fontSize;
                    margin: 0;
                    vertical-align: bottom;
                }
            }
            
            .ui-autocomplete-token {
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;
                margin: 3px;
                @include border-radius(50px);
                
                .ui-autocomplete-token-label {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 2px 2em 4px 2px;
                }
            }
            
            .ui-autocomplete-dropdown {
                top: .75em;
            }
        }
    }
    
    .ui-fluid {
        .ui-autocomplete {
            &.ui-autocomplete-dd {
                > .ui-inputtext {
                    width: 100%;    
                }
            }
        }
    }

    .ui-dropdown, .ui-dropdown-dark {
        border-bottom: 1px solid $zcBlack;
        box-sizing: border-box;
        @include border-radius(0); 
        @include transition(border-color .3s);      
        
        .ui-dropdown-trigger {
            height: 1.5em;
            width: 1.5em;
            margin-top: 0;
            padding: 0;
            top: 0;
            margin-right: 0;
            
            .fa {
                color: $textSecondaryColor;
                margin-top: 0;
                margin-left: 0;
            }
        }
        
        .ui-dropdown-label {
            &.ui-inputtext {
                font: $inputFontSize "OpenSans","Roboto","Helvetica Neue",sans-serif;
            }
        }
        
        &.ui-state-focus {

            .ui-dropdown-label {
                padding-bottom: 0;
            }
        }
    }

    .ui-dropdown-dark {
        border-bottom: 0;
   
        .ui-dropdown-label {
            &.ui-inputtext {
                color: $zcGhost;
            }
        }
    }

    .ui-selectbutton {
        .ui-button {
            padding: 0.4em 1em;
        }
    }
    
    .ui-dropdown-panel {
        @include border-radius(0);

        .ui-dropdown-list {
            padding: 0;
        }
        
        .ui-dropdown-item {
            margin: 0;
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            color: $textColor;
            @include transition(background-color .3s);
            @include border-radius(0);
            
            &.ui-state-highlight {
                color: $primaryColor;
                font-weight: 700;
                background: inherit;
            }

            &:hover {
                background: $zcPowder;
            }
        }

        &.ui-shadow {
            @include overlay-input-shadow();
        }
        
        .ui-dropdown-filter-container {
            display: block;
            border-bottom: 1px solid $zcBlack;
            background-color: $primaryColor;
            padding: $inputHeaderPadding;
            
            input {
                color: #ffffff;

                &:focus {
                    border-bottom-color: #ffffff;
                }
            }
            
            .fa {
                top: .325em;
                right: .75em;
                color: #ffffff;
            }
        }
    }
    
    .ui-multiselect, .ui-multiselect-dark {
        border-bottom: 1px solid $zcBlack;
        box-sizing: border-box;
        @include border-radius(0);
        @include transition(border-color .3s); 
        
        .ui-multiselect-label-container {
            padding-right: 1.5em;
            
            .ui-multiselect-label {
                padding: 0px 2px 0px 2px;
            }
        }
            
        .ui-multiselect-trigger {
            height: 1.5em;
            width: 1.5em;
            margin-top: 0;
            padding: 0;
            top: .063em;
            
            .fa {
                color: $textSecondaryColor;
                margin-top: auto;
                margin-left: auto;
            }
        } 
        
    }

    .ui-multiselect-dark {
        border-bottom: 0;
        
        .ui-multiselect-label-container {
            
            .ui-multiselect-label {
                color: $zcGhost;
            }
        }
            
    }
    
    .ui-multiselect-panel.ui-widget {
        padding: 0;
        @include border-radius(0);
        @include overlay-input-shadow();
        width: 100%;
        min-width: 12.5em;
                
        .ui-multiselect-header {
            @include border-radius(0);
            border: 0;
            border-bottom: 1px solid $zcSilver;
            padding: $inputHeaderPadding;
            position: relative;
            background-color: $zcWhite;
            
            .ui-chkbox {
                float: none;
                margin: 0 .5em 0 -.125em;
                position: static;
                
                .ui-chkbox-box {
                    
                    .ui-chkbox-icon {
                        border-color: #ffffff;
                    }
                    
                    &.ui-state-active {
                        .ui-chkbox-icon {
                            border-color: #ffffff;
                        }
                    }
                    
                    &.ui-state-focus {
                        background-color: $primaryLightColor;
                        @include transition(background-color .3s);
                    }
                }
            }

            .ui-multiselect-filter-container {
                width: 70%;
                display: inline-block;
                vertical-align: middle;
                float: none;
                margin-left: 0;
                
                .fa {
                    top: 0;
                    left: 0;
                    color: $zcGray;
                }  
                
                .ui-inputtext {
                    color: $zcGray;
                    padding-left: 1.5em;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 0px;
                }  
            }
            
            .ui-multiselect-close {
                position: absolute;
                right: .5em;
                top: .625em;
                height: 1.5em;
                width: 1.5em;
                
                span {
                    color: $zcGray;
                }
            }
        }
        
        .ui-multiselect-item {
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            color: $textColor;
            margin: 0px;
            @include border-radius(0);

            &.ui-state-highlight {
                background: inherit;
                color: $zcBlue;
                font-weight: 700;
            }

            &:hover {
                background: $zcPowder;
            }
        }
    }
        
    .ui-spinner {
        .ui-spinner-button {
            width: 1em;
            height: .75em;
            padding: 0;
            margin-right: .25em;
            background-color: transparent;
            color: $textColor;
            z-index: auto;
            @include no-shadow();
            
            .fa-triangle-1-n {
                color: $textColor;
            }
            
            .fa-triangle-1-s {
                color: $textColor;
            }    
            
            .fa {
                top: 0px;
                height: .5em;
                color: $textSecondaryColor;
            }

            &:enabled:hover,
            &:active {
                background-color: #ffffff;
            }
        }
        
        .ui-spinner-up {
            .fa {
                top: .25em;
            }
        }
        
        .ui-spinner-down {
            .fa {
                top: .167em;
            }
        }
        
        .ui-spinner-input {
            padding-right: 30px;
        }
    }
    
    .ui-fluid {
        .ui-spinner {
            .ui-spinner-button {
                width: 1em;
                height: .75em;

                .fa {
                    left: auto;
                }
            }
        }
    }
    
    .ui-inputswitch {
        height: 14px;
        width: 34px !important;
        overflow: visible;
        background-color: $zcSteel;
        border-color: #zcSteel;
        @include border-radius(8px);

        &.ui-state-disabled {
            opacity: 0.5;

            .ui-inputswitch-handle {
                background: $zcSilver;
            }
        }
    
        .ui-inputswitch-handle {
            top: -4px;
            background-color: #ffffff;
            @include border-radius(50%);
            @include transition(background-color .3s);
            width: 20px !important;
            height: 20px !important;
            -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
            -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
        }
        
        .ui-inputswitch-on {
            visibility: hidden;
        }
        
        .ui-inputswitch-off, .ui-inputswitch-on {
            span {
                visibility: hidden;
            }
        }
        
        &.ui-inputswitch-checked {
            background-color: $zcDodger;
            border-color: $zcDodger;
            
            .ui-inputswitch-handle {
                background-color: $zcBlue;
                color: $zcBlue;
            }
        }
    }
    
    .ui-slider {        
        .ui-slider-handle {
            background-color: $primaryColor;
            color: $primaryColor;
            @include border-radius(50%);
            @include ui-shadow-1();
            width: 1.25em;
            height: 1.25em;
            font-size: initial;
            cursor: -webkit-grab;
            transform: scale(.7);
            @include transition(all .4s cubic-bezier(.25,.8,.25,1));
            
            &.ui-state-focus {
                transform: scale(1);
            } 
            
            &:focus {
                outline: 0 none;
            }
        }
        
        &.ui-slider-horizontal {
            height: 2px;
            border: 0 none;
            background-color: $zcSlate;
            
            .ui-slider-handle {
                top: -.55em;
            }
        }
        
        &.ui-slider-vertical {
            width: 2px;
            border: 0 none;
            background-color: $zcSlate;
            
            .ui-slider-handle {
                left: -.55em;
            }
        }
        
        .ui-slider-range {
            height: 4px;
            position: relative;
            top: -1px;
            background-color: $zcDodger;
            color: $zcDodger;
        }

        &:not(.ui-state-disabled) {
            .ui-slider-handle {
                &:hover {
                    background-color: $zcRoyal;
                    color: $zcRoyal;
                    transform: scale(1);
                    cursor: -webkit-grab;
                    @include ui-shadow-2();
                }
                &:active {
                    cursor: -webkit-grabbing;
                }
            }
        }
    }
    
    .ui-calendar {
        .ui-datepicker-trigger {
            bottom: 2px;
            right: 0;
            background-color: transparent;
            color: $textColor;
            height: 1.5em;
            width: 1.5em;
            @include no-shadow();
            @include border-radius(0);
            
            .fa {
                color: $textSecondaryColor;
            }
            
            &:enabled:hover {
                background-color: transparent;
            }
        }
    }

    .ui-fluid {
        .ui-calendar.ui-calendar-w-btn {
            .ui-inputtext {
                width: 100%;
            }
        }
    }
    
    .ui-datepicker {
        padding: 0;
        width: 17.5em;
        
        &.ui-shadow {
            @include overlay-input-shadow();
        }
        
        .ui-datepicker-header {
            padding: $inputHeaderPadding;
            font-size: $inputHeaderFontSize;
            background: $primaryDarkColor;
            border-color: $primaryDarkColor;
            @include border-radius-top(2px);
            @include border-radius-bottom(0);
                        
            .ui-datepicker-prev {
                cursor: pointer;
                top: .4em;
                left: .2em;  
                @include border-radius(50%);
                @include material-icon("keyboard_arrow_left");                
                @include transition(background-color .3s);
                color: #ffffff;
                
                .fa {
                    display: none;
                }
                
                &:hover {
                    background-color: $primaryLightColor;
                    text-decoration: none;                    
                }
            }
                        
            .ui-datepicker-next {
                cursor: pointer;
                top: .4em;
                right: .2em;
                @include material-icon("keyboard_arrow_right");
                @include border-radius(50%);               
                @include transition(background-color .3s);
                color: #ffffff;
                
                .fa {
                    display: none;
                }
                
                &:hover {
                    background-color: $primaryLightColor;  
                    text-decoration: none;                  
                }
            }
            
            select.ui-datepicker-month,
            select.ui-datepicker-year {
                font-size: $fontSize - 2;   
            }
        }
        
        table {
            table-layout: fixed;
            border-spacing: 0;
            border-collapse: collapse;
            font-size: $fontSize - 2;
        }
        
        thead {
            tr {
                color: #ffffff;
                background: $primaryColor;
            }
        }
        
        tbody {
            td {
                padding: .25em .125em;
                box-sizing: border-box;

                &.ui-datepicker-today {
                    a,span {
                        background: $zcGhost;
                        border: 1px solid $zcBlue;
                        line-height: 1.6em;
                        
                        &.ui-state-active {
                            color: $zcWhite;
                            background-color: $primaryColor;
                            color: $accentTextColor;
                        }
                    }
                }
                
                a,span {
                    padding: .25em;
                    margin: 0;
                    text-align: center;
                    color: $textColor;
                    display: inline-block;
                    height: 2.250em;
                    width: 2.250em;
                    @include border-radius(50%);
                    line-height: 1.8em;
                                        
                    &.ui-state-active {
                        color: #ffffff;
                        background-color: $primaryColor;
                        color: $accentTextColor;
                    }

                    &:not(.ui-state-active):not(.ui-state-highlight):hover {
                        @include hover-element();
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }                
            }
        }
                
        .ui-timepicker {     
            @include border-radius(0);
            border: 0;
            background: $zcWhite;
            
            > div {
                color: $zcBlack;

                a {            
                    height: 1.5em;
                    
                    > span {
                        display: inline-block;
                        margin-bottom: .25em;
                    }
                           
                    .fa {
                        color: $zcBlue;

                        &.fa-angle-up {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_up");
                        }
                        
                        &.fa-angle-down {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_down");
                        }
                    }  
                }
            }                       
        }
    }
    
    #ui-datepicker-div {
        @include overlay-input-shadow();
    }
    
    .ui-rating {
        min-height: 1.5em;
        
        .fa-ban {
            @include material-icon("cancel");
            color: $textSecondaryColor;
        }

        .fa-star {
            @include material-icon("star");
            color: $textSecondaryColor;
        }
        
        .fa-star-o {
            @include material-icon("star_border");
            color: $textSecondaryColor;
        }
    }
    
    .ui-chips, .ui-chips-dark {
        > ul.ui-inputtext {
            padding: 2px 2px 1px 2px;

            &.ui-state-disabled {
                border-bottom: 1px dotted;
            }
            
            &.ui-state-focus {
                padding-bottom: 0;
            }
            
            .ui-chips-input-token {
                padding-bottom: 2px;
                position: relative;
                top: -4px;
                
                > input {
                    padding: 0;
                    font-size: $fontSize;
                    margin: 0;
                    vertical-align: bottom;
                }
                
                input:disabled {
                    width: 0;
                }
            }
            
            .ui-chips-token {
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;
                margin: 3px;
                padding-right: 2.5em;
                transition: all 0.3s;
                @include border-radius(100px);
                
                .ui-chips-token-label {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    top: -2px;
                    padding-left: 4px;
                    margin-right: 0;
                }
            }
        }
    }

    .ui-chips-dark {
        .ui-chips-input-token {
                > input {
                    padding: 0;
                    font-size: $fontSize;
                    color: $zcGhost;
                }
            }
    }
    
    .ui-password-panel {
        &.ui-shadow {
            @include overlay-input-shadow();
        }
    }
    
    .ui-fileupload {
        .ui-fileupload-buttonbar {
            padding: $headerPadding;
            background-color: transparent;
            border: 0;
            
            .fa-arrowreturnthick-1-n {
                @include icon_override('file_upload');
            }
            
            .ui-button {
                background-color: $primaryColor;
                
                &:enabled:hover {
                    background-color: $zcRoyal;
                }
            }
        }
        
        .ui-fileupload-content {

            border: 0;

            .ui-messages-error {
                .fa {
                    color: #ffffff;
                }
            }

            .ui-progressbar {
                height: 0px;
            }
        }
    }
    
    .ui-editor-container {
        .ui-editor-toolbar {
            background-color: #e8e8e8;
        }
        
        .ql-picker.ql-expanded {
            .ql-picker-label {
                color: #444444;
            }
        }
    }
    
    /* Validation */
    .ui-inputtext.ng-dirty.ng-invalid,
    p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
    p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
    p-calendar.ng-dirty.ng-invalid > .ui-inputtext,
    p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
    p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
    p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
    p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
    p-listbox.ng-dirty.ng-invalid .ui-inputtext,
    p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
    p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
    p-selectbutton.ng-dirty.ng-invalid .ui-button,
    p-togglebutton.ng-dirty.ng-invalid .ui-button {
        border-bottom-color: $invalidInputBorderColor;
    }

    .ui-button-group {
        background-color: $zcGunmetal;
        color: $zcWhite;
        height: 100%;
        margin: 0;
        border-radius: 0;

        &.ui-button {
            .ui-button-text {
                padding: $contentPadding;
            }

            &.selected {
                background-color: $zcBlue;
            }

            &.firstButton {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &.lastButton {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

@media (max-width: 640px) {
    body {                
        .ui-buttonset {
            > .ui-button {
                display: block;
                @include border-radius(0);
                
                &:first-child {
                    @include border-radius-top($borderRadius);
                }
                
                &:last-child {
                    @include border-radius-bottom($borderRadius);
                }
            }
        }
        .ui-splitbutton {
            > .ui-button {
                display: inline-block;

                &:first-child {
                    @include border-radius-top(0);
                }
                
                &:last-child {
                    @include border-radius-bottom(0);
                }
            }
        }
    }
}
