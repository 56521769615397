body {
    .ui-panel {
        padding: 0;
        @include border-radius(0px);
        border: 0px;

        .ui-panel-titlebar {
            border: 0 none;
            padding: $headerPadding;
            @include border-radius(0px);
            @include zf-display1-light();
            padding: 20px 40px;

            .ui-panel-title {
                line-height: 1.5em;
            }

            .ui-panel-titlebar-icon {
                position: relative;
                width: 1.5em;
                height: 1.5em;
                color: #ffffff;
                margin: 0;
                @include transition(background-color .3s);
                @include border-radius(50%);

                &:hover {
                    background-color: $primaryLightColor;
                }
            }
        }

        .ui-panel-content {
            height: 100%;
            box-sizing: border-box;
            padding: 20px 40px;

            .assign-card-buttons {
                margin-bottom: 0px;
            }
        }

        .ui-panel-footer {
            padding: $headerPadding;
            border: 0 none;
            border-top: 1px solid $dividerColor;
            margin: 0;
        }
    }

    .filters-ui {

        .ui-fieldset {
            border: none;
            background: none;

            .ui-fieldset-legend {
                text-align: right;
                font-size: 18px;

                .ui-fieldset-toggler {
                    display: none;
                }

            }

            .ui-fieldset-content-wrapper {
                background: #FFF;
                padding: 24px;
            }

        }

    }

    .ui-fieldset {
        padding: $contentPadding;

        .ui-fieldset-legend {
            padding: $headerPadding;
            padding-left: .125em;
            padding-right: .5em;
            color: $primaryColor;

            .ui-fieldset-toggler {
                padding: .5em;
                height: 2em;
                width: 2em;
                background-color: $primaryColor;
                color: #ffffff;
                font-size: 1.5em;
                @include border-radius(50%);
                @include transition(background-color .3s);
                margin-right: .5em;
                @include content-shadow();

                &:hover {
                    background-color: $primaryDarkColor;
                }
            }

            &.ui-state-focus {
                background-color: transparent;
            }
        }
    }

    .ui-accordion {
        .ui-accordion-header {
            background-color: $primaryColor;
            padding: 0;
            color:#ffffff;
            font-size: $headerFontSize;
            @include transition(background-color .3s);

            > a {
                padding: $headerPadding;
                padding-left: 2.5em;
                color: #ffffff;
            }

            &:not(.ui-state-active):not(.ui-state-disabled):hover {
                background-color: $primaryDarkColor;
            }

            &.ui-state-active {
                background-color: $accentColor;
                color: $accentTextColor;
                @include border-radius-bottom(0);

                > a {
                    color: $accentTextColor;
                }
            }

            .fa-caret-right {
                margin-top: -.5em;
                font-size: 1.5em;
            }

            .fa-caret-down {
                margin-top: -.5em;
                font-size: 1.5em;
            }
        }

        .ui-accordion-content {
            padding: $contentPadding;
            line-height: $lineHeight;
        }
    }

    .ui-toolbar {
        background-color: $primaryDarkColor;
        @include content-shadow();
        padding: .625em;
    }

    .ui-tabview {
        &.ui-widget-content {
            border: 0;
        }

        padding: 0;
        @include border-radius(0);
        .ui-tabview-nav {
            background-color: $zcGhost;
            border: 0 none;
            @include border-radius(0);

            > li {
                padding: 0;
                @include border-radius(0);
                @include transition(all .3s);
                // specifybordercolor .Used this because border-color is set to initial by primeng base
                color: $zcGhost;
                margin: 0;

                &:first-child {
                    //margin-left: $tabContainerMargin;
                }

                &:first-child {
                    //margin-left: $tabContainerMargin;
                }
                > a {
                    padding: $headerPadding;

                    &:focus {
                        outline: 0 none;
                    }
                }

                > .fa-close {
                    margin: .325em 0 0 0;
                    @include transition(color .3s);
                    color: $textSecondaryColor;
                }

                .ui-tabview-left-icon, .ui-tabview-right-icon {
                    color: $textSecondaryColor;
                    display: inline-block;
                    vertical-align: middle;
                }

                .ui-tabview-title {
                    padding: $contentPadding;
                    vertical-align: middle;
                    color: $zcBlack;
                }

                &.ui-state-default {
                    a {
                        color: $textSecondaryColor;
                    }
                }

                &:not(.ui-state-active):not(.ui-state-disabled):hover {
                    background-color: $zcSmoke;
                    border-color: $zcSmoke;
                }

                &.ui-state-active {
                    background-color: $zcWhite;
                    border-color: $accentColor;
                    border-style: solid;

                    a {
                        color: $primaryColor;
                    }

                    > .fa-close {
                        color: $accentColor;
                    }
                }

                &.ui-tabview-outline {
                    outline: 0 none;
                    border-color: $accentLightColor;
                }
            }
        }

        .ui-tabview-panel {
            padding: $contentPadding;
            margin: 24px 0;
        }

        &.ui-tabview-top {
            > .ui-tabview-nav {
                padding: 0;
                margin: 0;


                > li {
                    border-style: solid;
                    border-width: 2px 0 0 0;
                }
            }
        }

        &.ui-tabview-bottom {
            > .ui-tabview-nav {
                padding: 0;
                margin: 0;
                @include border-radius-bottom(4px);
                border-top: 1px solid $dividerColor;

                > li {
                    border-width: 2px 0 0 0;
                }
            }
        }

        &.ui-tabview-left {
            > .ui-tabview-nav {
                padding: 0;
                margin: 0;
                @include border-radius-left($borderRadius + 1);
                border-right: 1px solid $dividerColor;

                > li {
                    box-sizing: border-box;
                    border-width: 0 2px 0 0;

                    > a {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        &.ui-tabview-right {
            > .ui-tabview-nav {
                padding: 0;
                @include border-radius-right($borderRadius + 1);
                border-left: 1px solid $dividerColor;

                > li {
                    box-sizing: border-box;
                    border-width: 0 0 0 2px;

                    > a {
                        width: 100%;
                        box-sizing: border-box;
                    }

                    &.ui-state-active {
                        > a {
                            padding-left: 0.875em;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    body {
        .ui-toolbar {
            .ui-toolbar-group-right {
                margin-top: 1em;
            }
        }
    }
}
