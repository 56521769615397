body {
    /* Messages */
    .ui-messages {
        padding: $headerPadding;
        
        ul {
            display: inline-block;
            margin-left: 0;
        }
        
        &.ui-messages-info {
            background-color: #2196F3;
            border-color: #2196F3;
            color: #ffffff;
        }
        
        &.ui-messages-warn {
            background-color: #ffc107;
            border-color: #ffc107;
            color: #ffffff;
        }
        
        &.ui-messages-error {
            background-color: #e62a10;
            border-color: #e62a10;
            color: #ffffff;
        }
        
        &.ui-messages-fatal {
            background-color: #212121;
            border-color: #212121;
            color: #ffffff;
        }
        
        &.ui-messages-success {
            background-color: #8BC34A;
            border-color: #8BC34A;
            color: #ffffff;
        }
        
        .ui-messages-close {
            text-decoration: none;
            color: #fff;
            right: .25em;
        }
    }

    .ui-messages .ui-messages-icon {
        background: none;
        color: #fff;
        margin-top: -2px;
        
        &.fa-info-circle {
            @include material-icon("info");
            font-size: 1.75em;
        }
        
        &.fa-warning {
            @include material-icon("warning");
            font-size: 1.75em;
        }
        
        &.fa-close {
            @include material-icon("error_outline");
            font-size: 1.75em;
        }
        
        &.fa-check {
            @include material-icon("check_circle");
            font-size: 1.75em;
        }
    }
    
    .ui-growl {
        bottom: 16px;
        top: inherit;
        width: 400px;
        max-width: calc(100% - 40px);
        
        > .ui-growl-item-container {
            opacity: 1;
            background-color: $zcWhite;

            &.ui-growl-message-info {
                @include growl-gradient($zcBlue);
            }

            &.ui-growl-message-success {
                @include growl-gradient($zcPass);
            }

            &.ui-growl-message-warn {
                @include growl-gradient($zcWarning);
            }

            &.ui-growl-message-error {
                @include growl-gradient($zcCrit);
            }
        
            &.ui-shadow {
                @include overlay-content-shadow();
            }
        }
        
        .ui-growl-item {
            padding: 0;

            .ui-growl-image {
                background: none;
                color: $zcWhite;  
                font-size: 36px;
                
                &.fa-info-circle {
                    @include material-icon("info");
                    font-size: 1.75em;
                }
                
                &.fa-exclamation-circle {
                    @include material-icon("warning");
                    font-size: 1.75em;
                }
                
                &.fa-close {
                    @include material-icon("error_outline");
                    font-size: 1.75em;
                }
                
                &.fa-check {
                    @include material-icon("check_circle");
                    font-size: 1.75em;
                }   
            }
            
            .ui-growl-message {
                color: $zcBlack;
                margin-right: 1.5em;

                .ui-growl-title {
                    font-weight: 400;
                    padding: .5em;
                    margin-left: 1.25em;
                }

                p {
                    display: none;
                }
            }
            
            .ui-growl-icon-close {
                @include material-icon("close");
                font-size: 24px;
                color: $zcBlue;
            }        
        }
    }

    .ui-notice {
        background: $zcBlack;
        color: $zcGhost;
        padding: 8px 12px;
        @include border-radius(2px);
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            right: 12px;
            border-style: solid;
            border-width: 5px 9px 0;
            border-color: $zcBlack transparent;
            display: block;
        }
    }


}