body {
    .ui-widget,
    .ui-widget .ui-widget {
        font-family: OpenSans,"Roboto","Helvetica Neue",sans-serif;
        text-decoration: none;
    }

    .ui-widget-content {
        background-color: #ffffff;
        font-size: $contentFontSize;
        border: 1px solid $dividerColor;

        hr {
            border: 1px solid $zcGhost;
        }
        
        .fa {
            color: #757575;
        }
    }
    
    .ui-widget-header {
        background-color: $primaryColor;
        color: #ffffff;
        font-size: $headerFontSize;
        border: 1px solid $primaryColor;
        
        .fa {
            color: #ffffff;
        }
    }

    .ui-state-default {

    }

    .ui-state-hover {

    }

    .ui-state-focus {

    }

    .ui-state-active, .ui-state-highlight {
        background-color: $primaryColor;
        color: $accentTextColor;
        
        .fa {
            color: #ffffff;
        }
    }

    .ui-widget:disabled,
    .ui-state-disabled {
        opacity: .35;
        filter: Alpha(Opacity=35);
        background-image: none;
        cursor: auto !important;
    }

    .ui-corner-all {
        @include border-radius($borderRadius);
    }

    .ui-corner-top {
        @include border-radius-top($borderRadius);
    }

    .ui-corner-bottom {
        @include border-radius-bottom($borderRadius);
    }

    .ui-corner-left {
        @include border-radius-left($borderRadius);
    }

    .ui-corner-right {
        @include border-radius-right($borderRadius);
    }

    .ui-widget-overlay {
        background-color: $zcBlack;
		opacity: .9;
	}
	
	.ui-sidebar-mask {
		opacity: .6;
	}
    
    .ui-shadow {

    }
    
    a {
        
    }
}

.typography {
    margin: 12px 0 24px 24px;
}

.typography-headline { @include zf-headline(); }
.typography-display1 { @include zf-display1(); }
.typography-display1-light { @include zf-display1-light(); }
.typography-display2 { @include zf-display2(); }
.typography-display3 { @include zf-display3(); }
.typography-primary { @include zf-primary(); }
.typography-header { @include zf-header(); }
.typography-header-light { @include zf-header-light(); }
.typography-primary-gray { @include zf-primary-gray(); }
.typography-primary-slate { @include zf-primary-slate(); }
.typography-primary-light { @include zf-primary-light(); }
.typography-primary-white { @include zf-primary-white(); }
.typography-label { @include zf-label(); }
.typography-label-active { @include zf-label-active(); }
.typography-label-error { @include zf-label-error(); }
.typography-label-dark { @include zf-label-dark(); }
.typography-label-light { @include zf-label-light(); }

.color {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: 1px solid $zcGhost;
    position: relative;
    display: inline-block;
    text-align: center;
    margin: 40px;
}

.color p {
    position: absolute;
    top: 48px;
}

.centered {
    text-align: center;
}

.color-black { background-color: $zcBlack; }
.color-gunmetal { background-color: $zcGunmetal; }
.color-gray { background-color: $zcGray; }
.color-slate { background-color: $zcSlate; }
.color-steel { background-color: $zcSteel; }
.color-silver { background-color: $zcSilver; }
.color-ghost { background-color: $zcGhost; }
.color-smoke { background-color: $zcSmoke; }
.color-white { background-color: $zcWhite; }
.color-royal { background-color: $zcRoyal; }
.color-blue { background-color: $zcBlue; }
.color-dodger { background-color: $zcDodger; }
.color-powder { background-color: $zcPowder; }
.color-crit { background-color: $zcCrit; }
.color-notify { background-color: $zcNotify; }
.color-warning { background-color: $zcWarning; }
.color-pass { background-color: $zcPass; }

