@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin gradient($deg, $color1, $color2) {
    background: -moz-linear-gradient($deg, $color1 0%, $color2 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $color1), color-stop(100%, $color2)); /* safari4+,chrome */
    background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient($deg, $color1 0%, $color2 100%); /* opera 11.10+ */
    background: -ms-linear-gradient($deg, $color1 0%, $color2 100%); /* ie10+ */
    background: linear-gradient($deg, $color1 0%, $color2 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 ); /* ie6-9 */
}

@mixin growl-gradient($color1) {
    background: -moz-linear-gradient(left, $color1 0px, $color1 48px, $zcWhite 48px, $zcWhite 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0px, $color1), color-stop(48px, $color1), color-stop(48px, $zcWhite), color-stop(100%, $zcWhite)); /* safari4+,chrome */
    background: -webkit-linear-gradient(left, $color1 0px, $color1 48px, $zcWhite 48px, $zcWhite 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(left, $color1 0px, $color1 48px, $zcWhite 48px, $zcWhite 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(left, $color1 0px, $color1 48px, $zcWhite 48px, $zcWhite 100%); /* ie10+ */
    background: linear-gradient(to right, $color1 0px, $color1 48px, $zcWhite 48px, $zcWhite 100%); /* w3c */
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin content-shadow() {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12)
}

@mixin overlay-content-shadow() {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@mixin overlay-input-shadow() {
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin ui-shadow-1() {
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin ui-shadow-2() {
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

@mixin ui-shadow-3() {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@mixin ui-shadow-4() {
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@mixin ui-shadow-5() {
    -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin hover-element {
    background-color: $hoverBgColor;
    color: $hoverTextColor;
}

@mixin transition200 {
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

@mixin hover-element-primary {
    background-color: $primaryLightColor;
    color: $hoverTextColor;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin icon-override($icon) {
    &:before {
        content: $icon;
    }
}

@mixin gtc-override($icon) {
    &:before {
        content: $icon;
        font-family: 'GTC Icons' !important;
    }
}

@mixin material-icon($icon) {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &:before {
        content: $icon;
    }
}

@mixin gtc-icon($icon) {
    font-family: 'GTC Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &:before {
        content: $icon;
    }
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin rippleitem() {
    position: relative;
    overflow: hidden;
}


$zfWeightLight: 300 !default;
$zfWeightRegular: 400 !default;
$zfWeightBold: 700 !default;
$zfWeightXBold: 800 !default;

@mixin zf-headline {
    font-weight: $zfWeightRegular;
    font-size: 28px;
}

@mixin zf-display1 {
    font-weight: $zfWeightRegular;
    font-size: 24px;
}

@mixin zf-display1-light {
    font-weight: $zfWeightRegular;
    font-size: 24px;
    color: $zcSmoke;
}

@mixin zf-display2 {
    font-weight: $zfWeightRegular;
    font-size: 18px;
    color: $zcSilver;
}

@mixin zf-display3 {
    font-weight: $zfWeightRegular;
    font-size: 16px;
}

@mixin zf-header {
    font-size: 13px;
    font-weight: $zfWeightBold;
    text-transform: uppercase;
    color: $zcBlack;
}

@mixin zf-header-light {
    font-size: 13px;
    font-weight: $zfWeightBold;
    text-transform: uppercase;
    color: $zcGhost;
}

@mixin zf-primary {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcBlack;
}

@mixin zf-primary-gray {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcGray;
}

@mixin zf-primary-silver {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcSilver;
}

@mixin zf-primary-light {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcSmoke;
}

@mixin zf-primary-slate {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcSlate;
}

@mixin zf-primary-white {
    font-weight: $zfWeightRegular;
    font-size: 14px;
    color: $zcWhite;
}

@mixin zf-label {
    font-weight: $zfWeightRegular;
    font-size: 12px;
    color: $zcSlate;
}

@mixin zf-label-active {
    font-weight: $zfWeightRegular;
    @include zf-label();
    color: $zcBlue;
}

@mixin zf-label-error {
    font-weight: $zfWeightRegular;
    @include zf-label();
    color: $zcCrit;
}

@mixin zf-label-dark {
    font-weight: $zfWeightRegular;
    @include zf-label();
    color: $zcGray;
}

@mixin zf-label-light {
    font-weight: $zfWeightRegular;
    @include zf-label();
    color: $zcGhost;
}
