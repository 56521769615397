.exception-body {
    background-color: #f7f7f7;
    height: auto;
    
    .exception-type {
        width: 100%;
        height: 50%;
        padding: 100px 100px 0 100px;
        box-sizing: border-box;
        text-align: center;    
    }
    
    .exception-panel {
        text-align: center;
        width: 350px;
        padding: 35px;
        margin: -10% auto 0 auto;
        z-index: 100;
        
        i {
            font-size: 72px;
        }
        
        h1 {
            font-size: 36px;
            line-height: 36px;
            color: $textSecondaryColor;
        }
        
        .exception-detail {
            margin: 20px 0px 100px 0px;
            color: $textSecondaryColor;
        }
    }
    
    .ui-button {   
        @include border-radius(3px);
             
        &:hover {
            background-color: $accentDarkColor;
        }
    }
    
    &.error-page {
        .exception-type {
            background-color: #e62a10;
            
            img {
                width: 100%;
            }
        }
        
        .exception-panel {
            i {
                color: #f79a84;
            }
        }
    }
    
    &.notfound-page {
        .exception-type {
            background-color: #3f51b5;
            
            img {
                width: 54%;
            }
        }
        
        .exception-panel {
            i {
                color: #9fa8da;
            }
        }
    }
    
    &.accessdenied-page {
        .exception-type {
            background-color: #e91e63;
            
            img {
                width: 50%;
            }
        }
        
        .exception-panel {
            i {
                color: #f48fb1;
            }
        }
    }
}

@media (max-width: 1024px) {
    .exception-body {
        .exception-panel {
            margin-top: -50px;
        }
    }
}

@media (max-width: 640px) {
    .exception-body {
        .exception-panel {
            width: 250px;
            margin-top: -15px;
        }
    }
}
