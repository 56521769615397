@media print {
    
    .znr-toolbar-navbar {
        height: 42px;
        background-image: url(/zonar/lib/images/logo-sprite-sheet@2x.5050da4626990a04.png);
        background-repeat: no-repeat;
        background-position: 50% 0%;
        background-size: 300px 145px;
    }

    .main-content-section {
        padding: 40px 0px;
        background: #FFF;
    }

    .zlogs-list-main .zlogs-list-content {
        margin-left: 0px !important;
        max-width: 100% !important;
        overflow: visible;
    }

    .zlogs-list-content > zlogs-hos-timeline {
        max-width: 100% !important;
    }

    .menu-container, .znr-toolbar-navbar > div,
    .zonar-subnav,
    .zlogs-list-nav,
    .znr-toolbar-footer,
    #NG2_UPGRADE_3_0 > zonar-tabnav,
    .nav-button,
    .fa-calendar,
    body .ui-tabview .ui-tabview-nav .ui-state-default,
    .timeline-info-button {
        display: none;
    }

    .zlogs-list-content > driver-detail-nav {
        background: #FFF;
        border-bottom: 1px solid #293239;
    }

    #driver-detail-nav-name, .calendar-container > span {
        color: #000 !important;
    }

}