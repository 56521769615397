@import '../_variables.scss';

.fa-sort {@include icon-override("sort");}
.fa-sort-asc {@include icon-override("keyboard_arrow_up");}
.fa-sort-desc {@include icon-override("keyboard_arrow_down");}
.fa-angle-up {@include icon-override("play_arrow"); @include rotate(-90deg);}
.fa-angle-double-up { @include icon-override("skip_next"); @include rotate(-90deg);}
.fa-angle-down {@include icon-override("play_arrow"); @include rotate(90deg);}
.fa-angle-double-down {@include icon-override("skip_previous"); @include rotate(-90deg);}
.fa-angle-right {@include icon-override("play_arrow");}
.fa-angle-double-right {@include icon-override("fast_forward");}
.fa-angle-left {@include icon-override("play_arrow"); @include rotate(180deg);}
.fa-angle-double-left {@include icon-override("fast_rewind");}
.fa-check {@include icon-override("check");}
.fa-caret-up {@include icon-override("keyboard_arrow_up");}
.fa-caret-down {@include icon-override("keyboard_arrow_down");}
.fa-caret-right {@include icon-override("keyboard_arrow_right");}
.fa-caret-left {@include icon-override("keyboard_arrow_left");}
.fa-search {@include icon-override("search");}
.fa-close {@include icon-override("close");}
.fa-minus {@include icon-override("remove");}
.fa-plus {@include icon-override("add");}
.fa-check {@include icon-override("check");}
.fa-info-circle {@include icon-override("info");}
.fa-upload  {@include icon-override("file_upload");}
.fa-chevron-circle-left {@include icon-override("keyboard_arrow_left");}
.fa-chevron-circle-right {@include icon-override("keyboard_arrow_right");}
.fa-chevron-circle-down {@include icon-override("keyboard_arrow_down");}
.fa-chevron-circle-up {@include icon-override("keyboard_arrow_up");}
.fa-home {@include icon-override("home");}
.fa-chevron-right {@include icon-override("chevron_right");}
.fa-chevron-left {@include icon-override("chevron_left");}
.fa-circle-o{@include icon-override("radio_button_unchecked");}
.fa-dot-circle-o{@include icon-override("radio_button_checked");}
.fa-arrow-circle-right {@include icon-override("play_circle_outline"); }
.fa-arrow-circle-left {@include icon-override("play_circle_outline"); @include rotate(180deg); }
.fa-calendar { @include gtc-override("date_range"); }
.fa-arrow-down{ @include icon-override("arrow_downward"); }
.fa-arrow-up { @include icon-override("arrow_upward"); }

.ui-icon-carat-2-n-s {@include icon-override("sort");}
.ui-icon-triangle-1-n { @include icon-override("keyboard_arrow_up"); }
.ui-icon-triangle-1-e { @include icon-override("keyboard_arrow_right"); }
.ui-icon-triangle-1-s { @include icon-override("keyboard_arrow_down"); }
.ui-icon-triangle-1-w { @include icon-override("keyboard_arrow_left"); }
.ui-icon-carat-1-n { @include icon-override("keyboard_arrow_up"); }
.ui-icon-carat-1-e { @include icon-override("keyboard_arrow_right"); }
.ui-icon-carat-1-s { @include icon-override("keyboard_arrow_down"); }
.ui-icon-carat-1-w { @include icon-override("keyboard_arrow_left"); }
.ui-icon-arrow-1-n { @include icon-override("play_arrow"); @include rotate(-90deg);}
.ui-icon-arrowstop-1-n { @include icon-override("skip_next"); @include rotate(-90deg);}
.ui-icon-arrow-1-s { @include icon-override("play_arrow"); @include rotate(90deg);}
.ui-icon-arrowstop-1-s { @include icon-override("skip_previous"); @include rotate(-90deg);}
.ui-icon-arrow-1-w { @include icon-override("play_arrow"); @include rotate(180deg);}
.ui-icon-arrowstop-1-w { @include icon-override("fast_rewind"); }
.ui-icon-arrow-1-e { @include icon-override("play_arrow"); }
.ui-icon-arrowstop-1-e { @include icon-override("fast_forward"); }
.ui-icon-arrowthick-1-s { @include icon-override("play_arrow"); @include rotate(90deg);}
.ui-icon-arrowthick-1-n { @include icon-override("play_arrow"); @include rotate(-90deg);}
.ui-icon-circle-triangle-e {@include icon-override("play_circle_outline"); }
.ui-icon-circle-triangle-w {@include icon-override("play_circle_outline"); @include rotate(180deg);}
.ui-icon-circle-triangle-s {@include icon-override("play_circle_outline"); @include rotate(90deg);}
.ui-icon-radio-off {@include icon-override("radio_button_unchecked");}
.ui-icon-radio-on {@include icon-override("radio_button_checked");}
.ui-icon-folder-collapsed {@include icon-override("folder");}
.ui-icon-document {@include icon-override("content_paste");}
.ui-icon-video {@include icon-override("movie");}
.ui-icon-music {@include icon-override("music_note");}
.ui-icon-plus {@include icon-override("add");}
.ui-icon-minus {@include icon-override("remove");}
.ui-icon-plusthick {@include icon-override("add");}
.ui-icon-minusthick {@include icon-override("remove");}
.ui-icon-pencil {@include icon-override("edit");}
.ui-icon-closethick { @include icon-override("close"); }
.ui-icon-circle-close { @include icon-override("cancel"); }
.ui-icon-gear { @include icon-override("settings"); }
.ui-icon-calendar { @include gtc-override("date_range"); }
.ui-icon-trash { @include icon-override("delete_forever"); }
.ui-icon-notice { @include icon-override("error_outline"); }
.ui-icon-alert { @include icon-override("warning"); }
.ui-icon-circle-zoomin { @include icon-override("zoom_in"); }
.ui-icon-circle-zoomout { @include icon-override("zoom_out"); }
.ui-icon-circle-arrow-e { @include icon-override("play_circle_outline"); }
.ui-icon-circle-arrow-w { @include icon-override("play_circle_outline"); @include rotate(180deg); }

.ui-icon-3d-rotation { @include icon-override("3d_rotation"); }
.ui-icon-ac-unit { @include icon-override("ac_unit"); }
.ui-icon-access-alarm { @include icon-override("access_alarm"); }
.ui-icon-access-alarms { @include icon-override("access_alarms"); }
.ui-icon-access-time { @include icon-override("access_time"); }
.ui-icon-accessibility { @include icon-override("accessibility"); }
.ui-icon-accessible { @include icon-override("accessible"); }
.ui-icon-account-balance { @include icon-override("account_balance"); }
.ui-icon-account-balance-wallet { @include icon-override("account_balance_wallet"); }
.ui-icon-account-box { @include icon-override("account_box"); }
.ui-icon-account-circle { @include icon-override("account_circle"); }
.ui-icon-add { @include icon-override("add"); }
.ui-icon-add-a-photo { @include icon-override("add_a_photo"); }
.ui-icon-add-alarm { @include icon-override("add_alarm"); }
.ui-icon-add-alert { @include icon-override("add_alert"); }
.ui-icon-add-box { @include icon-override("add_box"); }
.ui-icon-add-circle { @include icon-override("add_circle"); }
.ui-icon-add-circle-outline { @include icon-override("add_circle_outline"); }
.ui-icon-add-location { @include icon-override("add_location"); }
.ui-icon-add-shopping-cart { @include icon-override("add_shopping_cart"); }
.ui-icon-add-to-photos { @include icon-override("add_to_photos"); }
.ui-icon-add-to-queue { @include icon-override("add_to_queue"); }
.ui-icon-adjust { @include icon-override("adjust"); }
.ui-icon-airline-seat-flat { @include icon-override("airline_seat_flat"); }
.ui-icon-airline-seat-flat-angled { @include icon-override("airline_seat_flat_angled"); }
.ui-icon-airline-seat-individual-suite { @include icon-override("airline_seat_individual_suite"); }
.ui-icon-airline-seat-legroom-extra { @include icon-override("airline_seat_legroom_extra"); }
.ui-icon-airline-seat-legroom-normal { @include icon-override("airline_seat_legroom_normal"); }
.ui-icon-airline-seat-legroom-reduced { @include icon-override("airline_seat_legroom_reduced"); }
.ui-icon-airline-seat-recline-extra { @include icon-override("airline_seat_recline_extra"); }
.ui-icon-airline-seat-recline-normal { @include icon-override("airline_seat_recline_normal"); }
.ui-icon-airplanemode-active { @include icon-override("airplanemode_active"); }
.ui-icon-airplanemode-inactive { @include icon-override("airplanemode_inactive"); }
.ui-icon-airplay { @include icon-override("airplay"); }
.ui-icon-airport-shuttle { @include icon-override("airport_shuttle"); }
.ui-icon-alarm { @include icon-override("alarm"); }
.ui-icon-alarm-add { @include icon-override("alarm_add"); }
.ui-icon-alarm-off { @include icon-override("alarm_off"); }
.ui-icon-alarm-on { @include icon-override("alarm_on"); }
.ui-icon-album { @include icon-override("album"); }
.ui-icon-all-inclusive { @include icon-override("all_inclusive"); }
.ui-icon-all-out { @include icon-override("all_out"); }
.ui-icon-android { @include icon-override("android"); }
.ui-icon-announcement { @include icon-override("announcement"); }
.ui-icon-apps { @include icon-override("apps"); }
.ui-icon-archive { @include icon-override("archive"); }
.ui-icon-arrow-back { @include icon-override("arrow_back"); }
.ui-icon-arrow-downward { @include icon-override("arrow_downward"); }
.ui-icon-arrow-drop-down { @include icon-override("arrow_drop_down"); }
.ui-icon-arrow-drop-down-circle { @include icon-override("arrow_drop_down_circle"); }
.ui-icon-arrow-drop-up { @include icon-override("arrow_drop_up"); }
.ui-icon-arrow-forward { @include icon-override("arrow_forward"); }
.ui-icon-arrow-upward { @include icon-override("arrow_upward"); }
.ui-icon-art-track { @include icon-override("art_track"); }
.ui-icon-aspect-ratio { @include icon-override("aspect_ratio"); }
.ui-icon-assessment { @include icon-override("assessment"); }
.ui-icon-assignment { @include icon-override("assignment"); }
.ui-icon-assignment-ind { @include icon-override("assignment_ind"); }
.ui-icon-assignment-late { @include icon-override("assignment_late"); }
.ui-icon-assignment-return { @include icon-override("assignment_return"); }
.ui-icon-assignment-returned { @include icon-override("assignment_returned"); }
.ui-icon-assignment-turned-in { @include icon-override("assignment_turned_in"); }
.ui-icon-assistant { @include icon-override("assistant"); }
.ui-icon-assistant-photo { @include icon-override("assistant_photo"); }
.ui-icon-attach-file { @include icon-override("attach_file"); }
.ui-icon-attach-money { @include icon-override("attach_money"); }
.ui-icon-attachment { @include icon-override("attachment"); }
.ui-icon-audiotrack { @include icon-override("audiotrack"); }
.ui-icon-autorenew { @include icon-override("autorenew"); }
.ui-icon-av-timer { @include icon-override("av_timer"); }
.ui-icon-backspace { @include icon-override("backspace"); }
.ui-icon-backup { @include icon-override("backup"); }
.ui-icon-battery-alert { @include icon-override("battery_alert"); }
.ui-icon-battery-charging-full { @include icon-override("battery_charging_full"); }
.ui-icon-battery-full { @include icon-override("battery_full"); }
.ui-icon-battery-std { @include icon-override("battery_std"); }
.ui-icon-battery-unknown { @include icon-override("battery_unknown"); }
.ui-icon-beach-access { @include icon-override("beach_access"); }
.ui-icon-beenhere { @include icon-override("beenhere"); }
.ui-icon-block { @include icon-override("block"); }
.ui-icon-bluetooth { @include icon-override("bluetooth"); }
.ui-icon-bluetooth-audio { @include icon-override("bluetooth_audio"); }
.ui-icon-bluetooth-connected { @include icon-override("bluetooth_connected"); }
.ui-icon-bluetooth-disabled { @include icon-override("bluetooth_disabled"); }
.ui-icon-bluetooth-searching { @include icon-override("bluetooth_searching"); }
.ui-icon-blur-circular { @include icon-override("blur_circular"); }
.ui-icon-blur-linear { @include icon-override("blur_linear"); }
.ui-icon-blur-off { @include icon-override("blur_off"); }
.ui-icon-blur-on { @include icon-override("blur_on"); }
.ui-icon-book { @include icon-override("book"); }
.ui-icon-bookmark { @include icon-override("bookmark"); }
.ui-icon-bookmark-border { @include icon-override("bookmark_border"); }
.ui-icon-border-all { @include icon-override("border_all"); }
.ui-icon-border-bottom { @include icon-override("border_bottom"); }
.ui-icon-border-clear { @include icon-override("border_clear"); }
.ui-icon-border-color { @include icon-override("border_color"); }
.ui-icon-border-horizontal { @include icon-override("border_horizontal"); }
.ui-icon-border-inner { @include icon-override("border_inner"); }
.ui-icon-border-left { @include icon-override("border_left"); }
.ui-icon-border-outer { @include icon-override("border_outer"); }
.ui-icon-border-right { @include icon-override("border_right"); }
.ui-icon-border-style { @include icon-override("border_style"); }
.ui-icon-border-top { @include icon-override("border_top"); }
.ui-icon-border-vertical { @include icon-override("border_vertical"); }
.ui-icon-branding-watermark { @include icon-override("branding_watermark"); }
.ui-icon-brightness-1 { @include icon-override("brightness_1"); }
.ui-icon-brightness-2 { @include icon-override("brightness_2"); }
.ui-icon-brightness-3 { @include icon-override("brightness_3"); }
.ui-icon-brightness-4 { @include icon-override("brightness_4"); }
.ui-icon-brightness-5 { @include icon-override("brightness_5"); }
.ui-icon-brightness-6 { @include icon-override("brightness_6"); }
.ui-icon-brightness-7 { @include icon-override("brightness_7"); }
.ui-icon-brightness-auto { @include icon-override("brightness_auto"); }
.ui-icon-brightness-high { @include icon-override("brightness_high"); }
.ui-icon-brightness-low { @include icon-override("brightness_low"); }
.ui-icon-brightness-medium { @include icon-override("brightness_medium"); }
.ui-icon-broken-image { @include icon-override("broken_image"); }
.ui-icon-brush { @include icon-override("brush"); }
.ui-icon-bubble-chart { @include icon-override("bubble_chart"); }
.ui-icon-bug-report { @include icon-override("bug_report"); }
.ui-icon-build { @include icon-override("build"); }
.ui-icon-bulk-edit { @include gtc-override("playlist_add_check"); }
.ui-icon-burst-mode { @include icon-override("burst_mode"); }
.ui-icon-business { @include icon-override("business"); }
.ui-icon-business-center { @include icon-override("business_center"); }
.ui-icon-cached { @include icon-override("cached"); }
.ui-icon-cake { @include icon-override("cake"); }
.ui-icon-call { @include icon-override("call"); }
.ui-icon-call-end { @include icon-override("call_end"); }
.ui-icon-call-made { @include icon-override("call_made"); }
.ui-icon-call-merge { @include icon-override("call_merge"); }
.ui-icon-call-missed { @include icon-override("call_missed"); }
.ui-icon-call-missed-outgoing { @include icon-override("call_missed_outgoing"); }
.ui-icon-call-received { @include icon-override("call_received"); }
.ui-icon-call-split { @include icon-override("call_split"); }
.ui-icon-call-to-action { @include icon-override("call_to_action"); }
.ui-icon-camera { @include icon-override("camera"); }
.ui-icon-camera-alt { @include icon-override("camera_alt"); }
.ui-icon-camera-enhance { @include icon-override("camera_enhance"); }
.ui-icon-camera-front { @include icon-override("camera_front"); }
.ui-icon-camera-rear { @include icon-override("camera_rear"); }
.ui-icon-camera-roll { @include icon-override("camera_roll"); }
.ui-icon-cancel { @include icon-override("cancel"); }
.ui-icon-card-giftcard { @include icon-override("card_giftcard"); }
.ui-icon-card-membership { @include icon-override("card_membership"); }
.ui-icon-card-travel { @include icon-override("card_travel"); }
.ui-icon-casino { @include icon-override("casino"); }
.ui-icon-cast { @include icon-override("cast"); }
.ui-icon-cast-connected { @include icon-override("cast_connected"); }
.ui-icon-center-focus-strong { @include icon-override("center_focus_strong"); }
.ui-icon-center-focus-weak { @include icon-override("center_focus_weak"); }
.ui-icon-change-history { @include icon-override("change_history"); }
.ui-icon-chat { @include icon-override("chat"); }
.ui-icon-chat-bubble { @include icon-override("chat_bubble"); }
.ui-icon-chat-bubble-outline { @include icon-override("chat_bubble_outline"); }
.ui-icon-check { @include icon-override("check"); }
.ui-icon-check-box { @include icon-override("check_box"); }
.ui-icon-check-box-outline-blank { @include icon-override("check_box_outline_blank"); }
.ui-icon-check-circle { @include icon-override("check_circle"); }
.ui-icon-chevron-left { @include icon-override("chevron_left"); }
.ui-icon-chevron-right { @include icon-override("chevron_right"); }
.ui-icon-child-care { @include icon-override("child_care"); }
.ui-icon-child-friendly { @include icon-override("child_friendly"); }
.ui-icon-chrome-reader-mode { @include icon-override("chrome_reader_mode"); }
.ui-icon-class { @include icon-override("class"); }
.ui-icon-clear { @include icon-override("clear"); }
.ui-icon-clear-all { @include icon-override("clear_all"); }
.ui-icon-close { @include icon-override("close"); }
.ui-icon-closed-caption { @include icon-override("closed_caption"); }
.ui-icon-cloud { @include icon-override("cloud"); }
.ui-icon-cloud-circle { @include icon-override("cloud_circle"); }
.ui-icon-cloud-done { @include icon-override("cloud_done"); }
.ui-icon-cloud-download { @include icon-override("cloud_download"); }
.ui-icon-cloud-off { @include icon-override("cloud_off"); }
.ui-icon-cloud-queue { @include icon-override("cloud_queue"); }
.ui-icon-cloud-upload { @include icon-override("cloud_upload"); }
.ui-icon-code { @include icon-override("code"); }
.ui-icon-collections { @include icon-override("collections"); }
.ui-icon-collections-bookmark { @include icon-override("collections_bookmark"); }
.ui-icon-color-lens { @include icon-override("color_lens"); }
.ui-icon-colorize { @include icon-override("colorize"); }
.ui-icon-comment { @include icon-override("comment"); }
.ui-icon-compare { @include icon-override("compare"); }
.ui-icon-compare-arrows { @include icon-override("compare_arrows"); }
.ui-icon-computer { @include icon-override("computer"); }
.ui-icon-confirmation-number { @include icon-override("confirmation_number"); }
.ui-icon-contact-mail { @include icon-override("contact_mail"); }
.ui-icon-contact-phone { @include icon-override("contact_phone"); }
.ui-icon-contacts { @include icon-override("contacts"); }
.ui-icon-content-copy { @include icon-override("content_copy"); }
.ui-icon-content-cut { @include icon-override("content_cut"); }
.ui-icon-content-paste { @include icon-override("content_paste"); }
.ui-icon-control-point { @include icon-override("control_point"); }
.ui-icon-control-point-duplicate { @include icon-override("control_point_duplicate"); }
.ui-icon-copyright { @include icon-override("copyright"); }
.ui-icon-create { @include icon-override("create"); }
.ui-icon-create-new-folder { @include icon-override("create_new_folder"); }
.ui-icon-credit-card { @include icon-override("credit_card"); }
.ui-icon-crop { @include icon-override("crop"); }
.ui-icon-crop-16-9 { @include icon-override("crop_16_9"); }
.ui-icon-crop-3-2 { @include icon-override("crop_3_2"); }
.ui-icon-crop-5-4 { @include icon-override("crop_5_4"); }
.ui-icon-crop-7-5 { @include icon-override("crop_7_5"); }
.ui-icon-crop-din { @include icon-override("crop_din"); }
.ui-icon-crop-free { @include icon-override("crop_free"); }
.ui-icon-crop-landscape { @include icon-override("crop_landscape"); }
.ui-icon-crop-original { @include icon-override("crop_original"); }
.ui-icon-crop-portrait { @include icon-override("crop_portrait"); }
.ui-icon-crop-rotate { @include icon-override("crop_rotate"); }
.ui-icon-crop-square { @include icon-override("crop_square"); }
.ui-icon-dashboard { @include icon-override("dashboard"); }
.ui-icon-data-usage { @include icon-override("data_usage"); }
.ui-icon-date-range { @include gtc-override("date_range"); }
.ui-icon-dehaze { @include icon-override("dehaze"); }
.ui-icon-delete { @include icon-override("delete"); }
.ui-icon-delete-forever { @include icon-override("delete_forever"); }
.ui-icon-delete-sweep { @include icon-override("delete_sweep"); }
.ui-icon-description { @include icon-override("description"); }
.ui-icon-desktop-mac { @include icon-override("desktop_mac"); }
.ui-icon-desktop-windows { @include icon-override("desktop_windows"); }
.ui-icon-details { @include icon-override("details"); }
.ui-icon-developer-board { @include icon-override("developer_board"); }
.ui-icon-developer-mode { @include icon-override("developer_mode"); }
.ui-icon-device-hub { @include icon-override("device_hub"); }
.ui-icon-devices { @include icon-override("devices"); }
.ui-icon-devices-other { @include icon-override("devices_other"); }
.ui-icon-dialer-sip { @include icon-override("dialer_sip"); }
.ui-icon-dialpad { @include icon-override("dialpad"); }
.ui-icon-directions { @include icon-override("directions"); }
.ui-icon-directions-bike { @include icon-override("directions_bike"); }
.ui-icon-directions-boat { @include icon-override("directions_boat"); }
.ui-icon-directions-bus { @include icon-override("directions_bus"); }
.ui-icon-directions-car { @include icon-override("directions_car"); }
.ui-icon-directions-railway { @include icon-override("directions_railway"); }
.ui-icon-directions-run { @include icon-override("directions_run"); }
.ui-icon-directions-subway { @include icon-override("directions_subway"); }
.ui-icon-directions-transit { @include icon-override("directions_transit"); }
.ui-icon-directions-walk { @include icon-override("directions_walk"); }
.ui-icon-disc-full { @include icon-override("disc_full"); }
.ui-icon-dns { @include icon-override("dns"); }
.ui-icon-do-not-disturb { @include icon-override("do_not_disturb"); }
.ui-icon-do-not-disturb-alt { @include icon-override("do_not_disturb_alt"); }
.ui-icon-do-not-disturb-off { @include icon-override("do_not_disturb_off"); }
.ui-icon-do-not-disturb-on { @include icon-override("do_not_disturb_on"); }
.ui-icon-dock { @include icon-override("dock"); }
.ui-icon-domain { @include icon-override("domain"); }
.ui-icon-done { @include icon-override("done"); }
.ui-icon-done-all { @include icon-override("done_all"); }
.ui-icon-donut-large { @include icon-override("donut_large"); }
.ui-icon-donut-small { @include icon-override("donut_small"); }
.ui-icon-drafts { @include icon-override("drafts"); }
.ui-icon-drag-handle { @include icon-override("drag_handle"); }
.ui-icon-drive-eta { @include icon-override("drive_eta"); }
.ui-icon-dvr { @include icon-override("dvr"); }
.ui-icon-edit { @include icon-override("edit"); }
.ui-icon-edit-location { @include icon-override("edit_location"); }
.ui-icon-eject { @include icon-override("eject"); }
.ui-icon-email { @include icon-override("email"); }
.ui-icon-enhanced-encryption { @include icon-override("enhanced_encryption"); }
.ui-icon-equalizer { @include icon-override("equalizer"); }
.ui-icon-error { @include icon-override("error"); }
.ui-icon-error-outline { @include icon-override("error_outline"); }
.ui-icon-euro-symbol { @include icon-override("euro_symbol"); }
.ui-icon-ev-station { @include icon-override("ev_station"); }
.ui-icon-event { @include icon-override("event"); }
.ui-icon-event-available { @include icon-override("event_available"); }
.ui-icon-event-busy { @include icon-override("event_busy"); }
.ui-icon-event-note { @include icon-override("event_note"); }
.ui-icon-event-seat { @include icon-override("event_seat"); }
.ui-icon-exit-to-app { @include icon-override("exit_to_app"); }
.ui-icon-expand-less { @include icon-override("expand_less"); }
.ui-icon-expand-more { @include icon-override("expand_more"); }
.ui-icon-explicit { @include icon-override("explicit"); }
.ui-icon-explore { @include icon-override("explore"); }
.ui-icon-export { @include gtc-override("export"); }
.ui-icon-exposure { @include icon-override("exposure"); }
.ui-icon-exposure-neg-1 { @include icon-override("exposure_neg_1"); }
.ui-icon-exposure-neg-2 { @include icon-override("exposure_neg_2"); }
.ui-icon-exposure-plus-1 { @include icon-override("exposure_plus_1"); }
.ui-icon-exposure-plus-2 { @include icon-override("exposure_plus_2"); }
.ui-icon-exposure-zero { @include icon-override("exposure_zero"); }
.ui-icon-extension { @include icon-override("extension"); }
.ui-icon-face { @include icon-override("face"); }
.ui-icon-fast-forward { @include icon-override("fast_forward"); }
.ui-icon-fast-rewind { @include icon-override("fast_rewind"); }
.ui-icon-favorite { @include icon-override("favorite"); }
.ui-icon-favorite-border { @include icon-override("favorite_border"); }
.ui-icon-featured-play-list { @include icon-override("featured_play_list"); }
.ui-icon-featured-video { @include icon-override("featured_video"); }
.ui-icon-feedback { @include icon-override("feedback"); }
.ui-icon-fiber-dvr { @include icon-override("fiber_dvr"); }
.ui-icon-fiber-manual-record { @include icon-override("fiber_manual_record"); }
.ui-icon-fiber-new { @include icon-override("fiber_new"); }
.ui-icon-fiber-pin { @include icon-override("fiber_pin"); }
.ui-icon-fiber-smart-record { @include icon-override("fiber_smart_record"); }
.ui-icon-file-download { @include icon-override("file_download"); }
.ui-icon-file-upload { @include icon-override("file_upload"); }
.ui-icon-filter { @include icon-override("filter"); }
.ui-icon-filter-1 { @include icon-override("filter_1"); }
.ui-icon-filter-2 { @include icon-override("filter_2"); }
.ui-icon-filter-3 { @include icon-override("filter_3"); }
.ui-icon-filter-4 { @include icon-override("filter_4"); }
.ui-icon-filter-5 { @include icon-override("filter_5"); }
.ui-icon-filter-6 { @include icon-override("filter_6"); }
.ui-icon-filter-7 { @include icon-override("filter_7"); }
.ui-icon-filter-8 { @include icon-override("filter_8"); }
.ui-icon-filter-9 { @include icon-override("filter_9"); }
.ui-icon-filter-9-plus { @include icon-override("filter_9_plus"); }
.ui-icon-filter-b-and-w { @include icon-override("filter_b_and_w"); }
.ui-icon-filter-center-focus { @include icon-override("filter_center_focus"); }
.ui-icon-filter-drama { @include icon-override("filter_drama"); }
.ui-icon-filter-frames { @include icon-override("filter_frames"); }
.ui-icon-filter-hdr { @include icon-override("filter_hdr"); }
.ui-icon-filter-list { @include icon-override("filter_list"); }
.ui-icon-filter-none { @include icon-override("filter_none"); }
.ui-icon-filter-tilt-shift { @include icon-override("filter_tilt_shift"); }
.ui-icon-filter-vintage { @include icon-override("filter_vintage"); }
.ui-icon-find-in-page { @include icon-override("find_in_page"); }
.ui-icon-find-replace { @include icon-override("find_replace"); }
.ui-icon-fingerprint { @include icon-override("fingerprint"); }
.ui-icon-first-page { @include gtc-override("first_page"); }
.ui-icon-fitness-center { @include icon-override("fitness_center"); }
.ui-icon-flag { @include icon-override("flag"); }
.ui-icon-flare { @include icon-override("flare"); }
.ui-icon-flash-auto { @include icon-override("flash_auto"); }
.ui-icon-flash-off { @include icon-override("flash_off"); }
.ui-icon-flash-on { @include icon-override("flash_on"); }
.ui-icon-flight { @include icon-override("flight"); }
.ui-icon-flight-land { @include icon-override("flight_land"); }
.ui-icon-flight-takeoff { @include icon-override("flight_takeoff"); }
.ui-icon-flip { @include icon-override("flip"); }
.ui-icon-flip-to-back { @include icon-override("flip_to_back"); }
.ui-icon-flip-to-front { @include icon-override("flip_to_front"); }
.ui-icon-folder { @include icon-override("folder"); }
.ui-icon-folder-open { @include icon-override("folder_open"); }
.ui-icon-folder-shared { @include icon-override("folder_shared"); }
.ui-icon-folder-special { @include icon-override("folder_special"); }
.ui-icon-font-download { @include icon-override("font_download"); }
.ui-icon-format-align-center { @include icon-override("format_align_center"); }
.ui-icon-format-align-justify { @include icon-override("format_align_justify"); }
.ui-icon-format-align-left { @include icon-override("format_align_left"); }
.ui-icon-format-align-right { @include icon-override("format_align_right"); }
.ui-icon-format-bold { @include icon-override("format_bold"); }
.ui-icon-format-clear { @include icon-override("format_clear"); }
.ui-icon-format-color-fill { @include icon-override("format_color_fill"); }
.ui-icon-format-color-reset { @include icon-override("format_color_reset"); }
.ui-icon-format-color-text { @include icon-override("format_color_text"); }
.ui-icon-format-indent-decrease { @include icon-override("format_indent_decrease"); }
.ui-icon-format-indent-increase { @include icon-override("format_indent_increase"); }
.ui-icon-format-italic { @include icon-override("format_italic"); }
.ui-icon-format-line-spacing { @include icon-override("format_line_spacing"); }
.ui-icon-format-list-bulleted { @include icon-override("format_list_bulleted"); }
.ui-icon-format-list-numbered { @include icon-override("format_list_numbered"); }
.ui-icon-format-paint { @include icon-override("format_paint"); }
.ui-icon-format-quote { @include icon-override("format_quote"); }
.ui-icon-format-shapes { @include icon-override("format_shapes"); }
.ui-icon-format-size { @include icon-override("format_size"); }
.ui-icon-format-strikethrough { @include icon-override("format_strikethrough"); }
.ui-icon-format-textdirection-l-to-r { @include icon-override("format_textdirection_l_to_r"); }
.ui-icon-format-textdirection-r-to-l { @include icon-override("format_textdirection_r_to_l"); }
.ui-icon-format-underlined { @include icon-override("format_underlined"); }
.ui-icon-forum { @include icon-override("forum"); }
.ui-icon-forward { @include icon-override("forward"); }
.ui-icon-forward-10 { @include icon-override("forward_10"); }
.ui-icon-forward-30 { @include icon-override("forward_30"); }
.ui-icon-forward-5 { @include icon-override("forward_5"); }
.ui-icon-free-breakfast { @include icon-override("free_breakfast"); }
.ui-icon-fullscreen { @include icon-override("fullscreen"); }
.ui-icon-fullscreen-exit { @include icon-override("fullscreen_exit"); }
.ui-icon-functions { @include icon-override("functions"); }
.ui-icon-g-translate { @include icon-override("g_translate"); }
.ui-icon-gamepad { @include icon-override("gamepad"); }
.ui-icon-games { @include icon-override("games"); }
.ui-icon-gavel { @include icon-override("gavel"); }
.ui-icon-gesture { @include icon-override("gesture"); }
.ui-icon-get-app { @include icon-override("get_app"); }
.ui-icon-gif { @include icon-override("gif"); }
.ui-icon-golf-course { @include icon-override("golf_course"); }
.ui-icon-gps-fixed { @include icon-override("gps_fixed"); }
.ui-icon-gps-not-fixed { @include icon-override("gps_not_fixed"); }
.ui-icon-gps-off { @include icon-override("gps_off"); }
.ui-icon-grade { @include icon-override("grade"); }
.ui-icon-gradient { @include icon-override("gradient"); }
.ui-icon-grain { @include icon-override("grain"); }
.ui-icon-graphic-eq { @include icon-override("graphic_eq"); }
.ui-icon-grid-off { @include icon-override("grid_off"); }
.ui-icon-grid-on { @include icon-override("grid_on"); }
.ui-icon-group { @include icon-override("group"); }
.ui-icon-group-add { @include icon-override("group_add"); }
.ui-icon-group-work { @include icon-override("group_work"); }
.ui-icon-hd { @include icon-override("hd"); }
.ui-icon-hdr-off { @include icon-override("hdr_off"); }
.ui-icon-hdr-on { @include icon-override("hdr_on"); }
.ui-icon-hdr-strong { @include icon-override("hdr_strong"); }
.ui-icon-hdr-weak { @include icon-override("hdr_weak"); }
.ui-icon-headset { @include icon-override("headset"); }
.ui-icon-headset-mic { @include icon-override("headset_mic"); }
.ui-icon-healing { @include icon-override("healing"); }
.ui-icon-hearing { @include icon-override("hearing"); }
.ui-icon-help { @include gtc-override("help"); }
.ui-icon-help-outline { @include icon-override("help_outline"); }
.ui-icon-high-quality { @include icon-override("high_quality"); }
.ui-icon-highlight { @include icon-override("highlight"); }
.ui-icon-highlight-off { @include icon-override("highlight_off"); }
.ui-icon-history { @include icon-override("history"); }
.ui-icon-home { @include icon-override("home"); }
.ui-icon-hot-tub { @include icon-override("hot_tub"); }
.ui-icon-hotel { @include icon-override("hotel"); }
.ui-icon-hourglass-empty { @include icon-override("hourglass_empty"); }
.ui-icon-hourglass-full { @include icon-override("hourglass_full"); }
.ui-icon-http { @include icon-override("http"); }
.ui-icon-https { @include icon-override("https"); }
.ui-icon-image { @include icon-override("image"); }
.ui-icon-image-aspect-ratio { @include icon-override("image_aspect_ratio"); }
.ui-icon-import { @include gtc-override("import"); }
.ui-icon-import-contacts { @include icon-override("import_contacts"); }
.ui-icon-import-export { @include icon-override("import_export"); }
.ui-icon-important-devices { @include icon-override("important_devices"); }
.ui-icon-inbox { @include icon-override("inbox"); }
.ui-icon-indeterminate-check-box { @include icon-override("indeterminate_check_box"); }
.ui-icon-info { @include icon-override("info"); }
.ui-icon-info-outline { @include icon-override("info_outline"); }
.ui-icon-input { @include icon-override("input"); }
.ui-icon-insert-chart { @include icon-override("insert_chart"); }
.ui-icon-insert-comment { @include icon-override("insert_comment"); }
.ui-icon-insert-drive-file { @include icon-override("insert_drive_file"); }
.ui-icon-insert-emoticon { @include icon-override("insert_emoticon"); }
.ui-icon-insert-invitation { @include icon-override("insert_invitation"); }
.ui-icon-insert-link { @include icon-override("insert_link"); }
.ui-icon-insert-photo { @include icon-override("insert_photo"); }
.ui-icon-invert-colors { @include icon-override("invert_colors"); }
.ui-icon-invert-colors-off { @include icon-override("invert_colors_off"); }
.ui-icon-iso { @include icon-override("iso"); }
.ui-icon-keyboard { @include icon-override("keyboard"); }
.ui-icon-keyboard-arrow-down { @include icon-override("keyboard_arrow_down"); }
.ui-icon-keyboard-arrow-left { @include icon-override("keyboard_arrow_left"); }
.ui-icon-keyboard-arrow-right { @include icon-override("keyboard_arrow_right"); }
.ui-icon-keyboard-arrow-up { @include icon-override("keyboard_arrow_up"); }
.ui-icon-keyboard-backspace { @include icon-override("keyboard_backspace"); }
.ui-icon-keyboard-capslock { @include icon-override("keyboard_capslock"); }
.ui-icon-keyboard-hide { @include icon-override("keyboard_hide"); }
.ui-icon-keyboard-return { @include icon-override("keyboard_return"); }
.ui-icon-keyboard-tab { @include icon-override("keyboard_tab"); }
.ui-icon-keyboard-voice { @include icon-override("keyboard_voice"); }
.ui-icon-kitchen { @include icon-override("kitchen"); }
.ui-icon-label { @include icon-override("label"); }
.ui-icon-label-outline { @include icon-override("label_outline"); }
.ui-icon-landscape { @include icon-override("landscape"); }
.ui-icon-language { @include icon-override("language"); }
.ui-icon-laptop { @include icon-override("laptop"); }
.ui-icon-laptop-chromebook { @include icon-override("laptop_chromebook"); }
.ui-icon-laptop-mac { @include icon-override("laptop_mac"); }
.ui-icon-laptop-windows { @include icon-override("laptop_windows"); }
.ui-icon-last-page { @include gtc-override("last_page"); }
.ui-icon-launch { @include icon-override("launch"); }
.ui-icon-layers { @include icon-override("layers"); }
.ui-icon-layers-clear { @include icon-override("layers_clear"); }
.ui-icon-leak-add { @include icon-override("leak_add"); }
.ui-icon-leak-remove { @include icon-override("leak_remove"); }
.ui-icon-lens { @include icon-override("lens"); }
.ui-icon-library-add { @include icon-override("library_add"); }
.ui-icon-library-books { @include icon-override("library_books"); }
.ui-icon-library-music { @include icon-override("library_music"); }
.ui-icon-lightbulb-outline { @include icon-override("lightbulb_outline"); }
.ui-icon-line-style { @include icon-override("line_style"); }
.ui-icon-line-weight { @include icon-override("line_weight"); }
.ui-icon-linear-scale { @include icon-override("linear_scale"); }
.ui-icon-link { @include icon-override("link"); }
.ui-icon-linked-camera { @include icon-override("linked_camera"); }
.ui-icon-list { @include icon-override("list"); }
.ui-icon-live-help { @include icon-override("live_help"); }
.ui-icon-live-tv { @include icon-override("live_tv"); }
.ui-icon-local-activity { @include icon-override("local_activity"); }
.ui-icon-local-airport { @include icon-override("local_airport"); }
.ui-icon-local-atm { @include icon-override("local_atm"); }
.ui-icon-local-bar { @include icon-override("local_bar"); }
.ui-icon-local-cafe { @include icon-override("local_cafe"); }
.ui-icon-local-car-wash { @include icon-override("local_car_wash"); }
.ui-icon-local-convenience-store { @include icon-override("local_convenience_store"); }
.ui-icon-local-dining { @include icon-override("local_dining"); }
.ui-icon-local-drink { @include icon-override("local_drink"); }
.ui-icon-local-florist { @include icon-override("local_florist"); }
.ui-icon-local-gas-station { @include icon-override("local_gas_station"); }
.ui-icon-local-grocery-store { @include icon-override("local_grocery_store"); }
.ui-icon-local-hospital { @include icon-override("local_hospital"); }
.ui-icon-local-hotel { @include icon-override("local_hotel"); }
.ui-icon-local-laundry-service { @include icon-override("local_laundry_service"); }
.ui-icon-local-library { @include icon-override("local_library"); }
.ui-icon-local-mall { @include icon-override("local_mall"); }
.ui-icon-local-movies { @include icon-override("local_movies"); }
.ui-icon-local-offer { @include icon-override("local_offer"); }
.ui-icon-local-parking { @include icon-override("local_parking"); }
.ui-icon-local-pharmacy { @include icon-override("local_pharmacy"); }
.ui-icon-local-phone { @include icon-override("local_phone"); }
.ui-icon-local-pizza { @include icon-override("local_pizza"); }
.ui-icon-local-play { @include icon-override("local_play"); }
.ui-icon-local-post-office { @include icon-override("local_post_office"); }
.ui-icon-local-printshop { @include icon-override("local_printshop"); }
.ui-icon-local-see { @include icon-override("local_see"); }
.ui-icon-local-shipping { @include icon-override("local_shipping"); }
.ui-icon-local-taxi { @include icon-override("local_taxi"); }
.ui-icon-location-city { @include icon-override("location_city"); }
.ui-icon-location-disabled { @include icon-override("location_disabled"); }
.ui-icon-location-off { @include icon-override("location_off"); }
.ui-icon-location-on { @include icon-override("location_on"); }
.ui-icon-location-searching { @include icon-override("location_searching"); }
.ui-icon-lock { @include icon-override("lock"); }
.ui-icon-lock-open { @include icon-override("lock_open"); }
.ui-icon-lock-outline { @include icon-override("lock_outline"); }
.ui-icon-looks { @include icon-override("looks"); }
.ui-icon-looks-3 { @include icon-override("looks_3"); }
.ui-icon-looks-4 { @include icon-override("looks_4"); }
.ui-icon-looks-5 { @include icon-override("looks_5"); }
.ui-icon-looks-6 { @include icon-override("looks_6"); }
.ui-icon-looks-one { @include icon-override("looks_one"); }
.ui-icon-looks-two { @include icon-override("looks_two"); }
.ui-icon-loop { @include icon-override("loop"); }
.ui-icon-loupe { @include icon-override("loupe"); }
.ui-icon-low-priority { @include icon-override("low_priority"); }
.ui-icon-loyalty { @include icon-override("loyalty"); }
.ui-icon-mail { @include icon-override("mail"); }
.ui-icon-mail-outline { @include icon-override("mail_outline"); }
.ui-icon-map { @include icon-override("map"); }
.ui-icon-markunread { @include icon-override("markunread"); }
.ui-icon-markunread-mailbox { @include icon-override("markunread_mailbox"); }
.ui-icon-memory { @include icon-override("memory"); }
.ui-icon-menu { @include icon-override("menu"); }
.ui-icon-merge-type { @include icon-override("merge_type"); }
.ui-icon-message { @include icon-override("message"); }
.ui-icon-mic { @include icon-override("mic"); }
.ui-icon-mic-none { @include icon-override("mic_none"); }
.ui-icon-mic-off { @include icon-override("mic_off"); }
.ui-icon-mms { @include icon-override("mms"); }
.ui-icon-mode-comment { @include icon-override("mode_comment"); }
.ui-icon-mode-edit { @include icon-override("mode_edit"); }
.ui-icon-monetization-on { @include icon-override("monetization_on"); }
.ui-icon-money-off { @include icon-override("money_off"); }
.ui-icon-monochrome-photos { @include icon-override("monochrome_photos"); }
.ui-icon-mood { @include icon-override("mood"); }
.ui-icon-mood-bad { @include icon-override("mood_bad"); }
.ui-icon-more { @include icon-override("more"); }
.ui-icon-more-horiz { @include icon-override("more_horiz"); }
.ui-icon-more-vert { @include icon-override("more_vert"); }
.ui-icon-motorcycle { @include icon-override("motorcycle"); }
.ui-icon-mouse { @include icon-override("mouse"); }
.ui-icon-move-to-inbox { @include icon-override("move_to_inbox"); }
.ui-icon-movie { @include icon-override("movie"); }
.ui-icon-movie-creation { @include icon-override("movie_creation"); }
.ui-icon-movie-filter { @include icon-override("movie_filter"); }
.ui-icon-multiline-chart { @include icon-override("multiline_chart"); }
.ui-icon-music-note { @include icon-override("music_note"); }
.ui-icon-music-video { @include icon-override("music_video"); }
.ui-icon-my-location { @include icon-override("my_location"); }
.ui-icon-nature { @include icon-override("nature"); }
.ui-icon-nature-people { @include icon-override("nature_people"); }
.ui-icon-navigate-before { @include icon-override("navigate_before"); }
.ui-icon-navigate-next { @include icon-override("navigate_next"); }
.ui-icon-navigation { @include icon-override("navigation"); }
.ui-icon-near-me { @include icon-override("near_me"); }
.ui-icon-network-cell { @include icon-override("network_cell"); }
.ui-icon-network-check { @include icon-override("network_check"); }
.ui-icon-network-locked { @include icon-override("network_locked"); }
.ui-icon-network-wifi { @include icon-override("network_wifi"); }
.ui-icon-new-releases { @include icon-override("new_releases"); }
.ui-icon-next-week { @include icon-override("next_week"); }
.ui-icon-nfc { @include icon-override("nfc"); }
.ui-icon-no-encryption { @include icon-override("no_encryption"); }
.ui-icon-no-sim { @include icon-override("no_sim"); }
.ui-icon-not-interested { @include icon-override("not_interested"); }
.ui-icon-note { @include icon-override("note"); }
.ui-icon-note-add { @include icon-override("note_add"); }
.ui-icon-notifications { @include icon-override("notifications"); }
.ui-icon-notifications-active { @include icon-override("notifications_active"); }
.ui-icon-notifications-none { @include icon-override("notifications_none"); }
.ui-icon-notifications-off { @include icon-override("notifications_off"); }
.ui-icon-notifications-paused { @include icon-override("notifications_paused"); }
.ui-icon-offline-pin { @include icon-override("offline_pin"); }
.ui-icon-ondemand-video { @include icon-override("ondemand_video"); }
.ui-icon-opacity { @include icon-override("opacity"); }
.ui-icon-open-in-browser { @include icon-override("open_in_browser"); }
.ui-icon-open-in-new { @include icon-override("open_in_new"); }
.ui-icon-open-with { @include icon-override("open_with"); }
.ui-icon-pages { @include icon-override("pages"); }
.ui-icon-pageview { @include icon-override("pageview"); }
.ui-icon-palette { @include icon-override("palette"); }
.ui-icon-pan-tool { @include icon-override("pan_tool"); }
.ui-icon-panorama { @include icon-override("panorama"); }
.ui-icon-panorama-fish-eye { @include icon-override("panorama_fish_eye"); }
.ui-icon-panorama-horizontal { @include icon-override("panorama_horizontal"); }
.ui-icon-panorama-vertical { @include icon-override("panorama_vertical"); }
.ui-icon-panorama-wide-angle { @include icon-override("panorama_wide_angle"); }
.ui-icon-party-mode { @include icon-override("party_mode"); }
.ui-icon-pause { @include icon-override("pause"); }
.ui-icon-pause-circle-filled { @include icon-override("pause_circle_filled"); }
.ui-icon-pause-circle-outline { @include icon-override("pause_circle_outline"); }
.ui-icon-payment { @include icon-override("payment"); }
.ui-icon-people { @include icon-override("people"); }
.ui-icon-people-outline { @include icon-override("people_outline"); }
.ui-icon-perm-camera-mic { @include icon-override("perm_camera_mic"); }
.ui-icon-perm-contact-calendar { @include icon-override("perm_contact_calendar"); }
.ui-icon-perm-data-setting { @include icon-override("perm_data_setting"); }
.ui-icon-perm-device-information { @include icon-override("perm_device_information"); }
.ui-icon-perm-identity { @include icon-override("perm_identity"); }
.ui-icon-perm-media { @include icon-override("perm_media"); }
.ui-icon-perm-phone-msg { @include icon-override("perm_phone_msg"); }
.ui-icon-perm-scan-wifi { @include icon-override("perm_scan_wifi"); }
.ui-icon-person { @include icon-override("person"); }
.ui-icon-person-add { @include icon-override("person_add"); }
.ui-icon-person-outline { @include icon-override("person_outline"); }
.ui-icon-person-pin { @include icon-override("person_pin"); }
.ui-icon-person-pin-circle { @include icon-override("person_pin_circle"); }
.ui-icon-personal-video { @include icon-override("personal_video"); }
.ui-icon-pets { @include icon-override("pets"); }
.ui-icon-phone { @include icon-override("phone"); }
.ui-icon-phone-android { @include icon-override("phone_android"); }
.ui-icon-phone-bluetooth-speaker { @include icon-override("phone_bluetooth_speaker"); }
.ui-icon-phone-forwarded { @include icon-override("phone_forwarded"); }
.ui-icon-phone-in-talk { @include icon-override("phone_in_talk"); }
.ui-icon-phone-iphone { @include icon-override("phone_iphone"); }
.ui-icon-phone-locked { @include icon-override("phone_locked"); }
.ui-icon-phone-missed { @include icon-override("phone_missed"); }
.ui-icon-phone-paused { @include icon-override("phone_paused"); }
.ui-icon-phonelink { @include icon-override("phonelink"); }
.ui-icon-phonelink-erase { @include icon-override("phonelink_erase"); }
.ui-icon-phonelink-lock { @include icon-override("phonelink_lock"); }
.ui-icon-phonelink-off { @include icon-override("phonelink_off"); }
.ui-icon-phonelink-ring { @include icon-override("phonelink_ring"); }
.ui-icon-phonelink-setup { @include icon-override("phonelink_setup"); }
.ui-icon-photo { @include icon-override("photo"); }
.ui-icon-photo-album { @include icon-override("photo_album"); }
.ui-icon-photo-camera { @include icon-override("photo_camera"); }
.ui-icon-photo-filter { @include icon-override("photo_filter"); }
.ui-icon-photo-library { @include icon-override("photo_library"); }
.ui-icon-photo-size-select-actual { @include icon-override("photo_size_select_actual"); }
.ui-icon-photo-size-select-large { @include icon-override("photo_size_select_large"); }
.ui-icon-photo-size-select-small { @include icon-override("photo_size_select_small"); }
.ui-icon-picture-as-pdf { @include icon-override("picture_as_pdf"); }
.ui-icon-picture-in-picture { @include icon-override("picture_in_picture"); }
.ui-icon-picture-in-picture-alt { @include icon-override("picture_in_picture_alt"); }
.ui-icon-pie-chart { @include icon-override("pie_chart"); }
.ui-icon-pie-chart-outlined { @include icon-override("pie_chart_outlined"); }
.ui-icon-pin-drop { @include icon-override("pin_drop"); }
.ui-icon-place { @include icon-override("place"); }
.ui-icon-play-arrow { @include icon-override("play_arrow"); }
.ui-icon-play-circle-filled { @include icon-override("play_circle_filled"); }
.ui-icon-play-circle-outline { @include icon-override("play_circle_outline"); }
.ui-icon-play-for-work { @include icon-override("play_for_work"); }
.ui-icon-playlist-add { @include icon-override("playlist_add"); }
.ui-icon-playlist-add-check { @include gtc-override("playlist_add_check"); }
.ui-icon-playlist-play { @include icon-override("playlist_play"); }
.ui-icon-plus-one { @include icon-override("plus_one"); }
.ui-icon-poll { @include icon-override("poll"); }
.ui-icon-polymer { @include icon-override("polymer"); }
.ui-icon-pool { @include icon-override("pool"); }
.ui-icon-portable-wifi-off { @include icon-override("portable_wifi_off"); }
.ui-icon-portrait { @include icon-override("portrait"); }
.ui-icon-power { @include icon-override("power"); }
.ui-icon-power-input { @include icon-override("power_input"); }
.ui-icon-power-settings-new { @include icon-override("power_settings_new"); }
.ui-icon-pregnant-woman { @include icon-override("pregnant_woman"); }
.ui-icon-present-to-all { @include icon-override("present_to_all"); }
.ui-icon-print { @include gtc-override("print"); }
.ui-icon-priority-high { @include icon-override("priority_high"); }
.ui-icon-public { @include icon-override("public"); }
.ui-icon-publish { @include icon-override("publish"); }
.ui-icon-query-builder { @include icon-override("query_builder"); }
.ui-icon-question-answer { @include icon-override("question_answer"); }
.ui-icon-queue { @include icon-override("queue"); }
.ui-icon-queue-music { @include icon-override("queue_music"); }
.ui-icon-queue-play-next { @include icon-override("queue_play_next"); }
.ui-icon-radio { @include icon-override("radio"); }
.ui-icon-radio-button-checked { @include icon-override("radio_button_checked"); }
.ui-icon-radio-button-unchecked { @include icon-override("radio_button_unchecked"); }
.ui-icon-rate-review { @include icon-override("rate_review"); }
.ui-icon-receipt { @include icon-override("receipt"); }
.ui-icon-recent-actors { @include icon-override("recent_actors"); }
.ui-icon-record-voice-over { @include icon-override("record_voice_over"); }
.ui-icon-redeem { @include icon-override("redeem"); }
.ui-icon-redo { @include icon-override("redo"); }
.ui-icon-refresh { @include icon-override("refresh"); }
.ui-icon-remove { @include icon-override("remove"); }
.ui-icon-remove-circle { @include icon-override("remove_circle"); }
.ui-icon-remove-circle-outline { @include icon-override("remove_circle_outline"); }
.ui-icon-remove-from-queue { @include icon-override("remove_from_queue"); }
.ui-icon-remove-red-eye { @include icon-override("remove_red_eye"); }
.ui-icon-remove-shopping-cart { @include icon-override("remove_shopping_cart"); }
.ui-icon-reorder { @include icon-override("reorder"); }
.ui-icon-repeat { @include icon-override("repeat"); }
.ui-icon-repeat-one { @include icon-override("repeat_one"); }
.ui-icon-replay { @include icon-override("replay"); }
.ui-icon-replay-10 { @include icon-override("replay_10"); }
.ui-icon-replay-30 { @include icon-override("replay_30"); }
.ui-icon-replay-5 { @include icon-override("replay_5"); }
.ui-icon-reply { @include icon-override("reply"); }
.ui-icon-reply-all { @include icon-override("reply_all"); }
.ui-icon-report { @include icon-override("report"); }
.ui-icon-report-problem { @include icon-override("report_problem"); }
.ui-icon-restaurant { @include icon-override("restaurant"); }
.ui-icon-restaurant-menu { @include icon-override("restaurant_menu"); }
.ui-icon-restore { @include icon-override("restore"); }
.ui-icon-restore-page { @include icon-override("restore_page"); }
.ui-icon-ring-volume { @include icon-override("ring_volume"); }
.ui-icon-room { @include icon-override("room"); }
.ui-icon-room-service { @include icon-override("room_service"); }
.ui-icon-rotate-90-degrees-ccw { @include icon-override("rotate_90_degrees_ccw"); }
.ui-icon-rotate-left { @include icon-override("rotate_left"); }
.ui-icon-rotate-right { @include icon-override("rotate_right"); }
.ui-icon-rounded-corner { @include icon-override("rounded_corner"); }
.ui-icon-router { @include icon-override("router"); }
.ui-icon-rowing { @include icon-override("rowing"); }
.ui-icon-rss-feed { @include icon-override("rss_feed"); }
.ui-icon-rv-hookup { @include icon-override("rv_hookup"); }
.ui-icon-satellite { @include icon-override("satellite"); }
.ui-icon-save { @include icon-override("save"); }
.ui-icon-scanner { @include icon-override("scanner"); }
.ui-icon-schedule { @include icon-override("schedule"); }
.ui-icon-school { @include icon-override("school"); }
.ui-icon-screen-lock-landscape { @include icon-override("screen_lock_landscape"); }
.ui-icon-screen-lock-portrait { @include icon-override("screen_lock_portrait"); }
.ui-icon-screen-lock-rotation { @include icon-override("screen_lock_rotation"); }
.ui-icon-screen-rotation { @include icon-override("screen_rotation"); }
.ui-icon-screen-share { @include icon-override("screen_share"); }
.ui-icon-sd-card { @include icon-override("sd_card"); }
.ui-icon-sd-storage { @include icon-override("sd_storage"); }
.ui-icon-search { @include icon-override("search"); }
.ui-icon-security { @include icon-override("security"); }
.ui-icon-select-all { @include icon-override("select_all"); }
.ui-icon-send { @include icon-override("send"); }
.ui-icon-sentiment-dissatisfied { @include icon-override("sentiment_dissatisfied"); }
.ui-icon-sentiment-neutral { @include icon-override("sentiment_neutral"); }
.ui-icon-sentiment-satisfied { @include icon-override("sentiment_satisfied"); }
.ui-icon-sentiment-very-dissatisfied { @include icon-override("sentiment_very_dissatisfied"); }
.ui-icon-sentiment-very-satisfied { @include icon-override("sentiment_very_satisfied"); }
.ui-icon-settings { @include icon-override("settings"); }
.ui-icon-settings-applications { @include icon-override("settings_applications"); }
.ui-icon-settings-backup-restore { @include icon-override("settings_backup_restore"); }
.ui-icon-settings-bluetooth { @include icon-override("settings_bluetooth"); }
.ui-icon-settings-brightness { @include icon-override("settings_brightness"); }
.ui-icon-settings-cell { @include icon-override("settings_cell"); }
.ui-icon-settings-ethernet { @include icon-override("settings_ethernet"); }
.ui-icon-settings-input-antenna { @include icon-override("settings_input_antenna"); }
.ui-icon-settings-input-component { @include icon-override("settings_input_component"); }
.ui-icon-settings-input-composite { @include icon-override("settings_input_composite"); }
.ui-icon-settings-input-hdmi { @include icon-override("settings_input_hdmi"); }
.ui-icon-settings-input-svideo { @include icon-override("settings_input_svideo"); }
.ui-icon-settings-overscan { @include icon-override("settings_overscan"); }
.ui-icon-settings-phone { @include icon-override("settings_phone"); }
.ui-icon-settings-power { @include icon-override("settings_power"); }
.ui-icon-settings-remote { @include icon-override("settings_remote"); }
.ui-icon-settings-system-daydream { @include icon-override("settings_system_daydream"); }
.ui-icon-settings-voice { @include icon-override("settings_voice"); }
.ui-icon-share { @include icon-override("share"); }
.ui-icon-shop { @include icon-override("shop"); }
.ui-icon-shop-two { @include icon-override("shop_two"); }
.ui-icon-shopping-basket { @include icon-override("shopping_basket"); }
.ui-icon-shopping-cart { @include icon-override("shopping_cart"); }
.ui-icon-short-text { @include icon-override("short_text"); }
.ui-icon-show-chart { @include icon-override("show_chart"); }
.ui-icon-shuffle { @include icon-override("shuffle"); }
.ui-icon-signal-cellular-4-bar { @include icon-override("signal_cellular_4_bar"); }
.ui-icon-signal-cellular-connected-no-internet-4-bar { @include icon-override("signal_cellular_connected_no_internet_4_bar"); }
.ui-icon-signal-cellular-no-sim { @include icon-override("signal_cellular_no_sim"); }
.ui-icon-signal-cellular-null { @include icon-override("signal_cellular_null"); }
.ui-icon-signal-cellular-off { @include icon-override("signal_cellular_off"); }
.ui-icon-signal-wifi-4-bar { @include icon-override("signal_wifi_4_bar"); }
.ui-icon-signal-wifi-4-bar-lock { @include icon-override("signal_wifi_4_bar_lock"); }
.ui-icon-signal-wifi-off { @include icon-override("signal_wifi_off"); }
.ui-icon-sim-card { @include icon-override("sim_card"); }
.ui-icon-sim-card-alert { @include icon-override("sim_card_alert"); }
.ui-icon-skip-next { @include icon-override("skip_next"); }
.ui-icon-skip-previous { @include icon-override("skip_previous"); }
.ui-icon-slideshow { @include icon-override("slideshow"); }
.ui-icon-slow-motion-video { @include icon-override("slow_motion_video"); }
.ui-icon-smartphone { @include icon-override("smartphone"); }
.ui-icon-smoke-free { @include icon-override("smoke_free"); }
.ui-icon-smoking-rooms { @include icon-override("smoking_rooms"); }
.ui-icon-sms { @include icon-override("sms"); }
.ui-icon-sms-failed { @include icon-override("sms_failed"); }
.ui-icon-snooze { @include gtc-override("snooze"); }
.ui-icon-sort { @include icon-override("sort"); }
.ui-icon-sort-by-alpha { @include icon-override("sort_by_alpha"); }
.ui-icon-spa { @include icon-override("spa"); }
.ui-icon-space-bar { @include icon-override("space_bar"); }
.ui-icon-speaker { @include icon-override("speaker"); }
.ui-icon-speaker-group { @include icon-override("speaker_group"); }
.ui-icon-speaker-notes { @include icon-override("speaker_notes"); }
.ui-icon-speaker-notes-off { @include icon-override("speaker_notes_off"); }
.ui-icon-speaker-phone { @include icon-override("speaker_phone"); }
.ui-icon-spellcheck { @include icon-override("spellcheck"); }
.ui-icon-star { @include icon-override("star"); }
.ui-icon-star-border { @include icon-override("star_border"); }
.ui-icon-star-half { @include icon-override("star_half"); }
.ui-icon-stars { @include icon-override("stars"); }
.ui-icon-stay-current-landscape { @include icon-override("stay_current_landscape"); }
.ui-icon-stay-current-portrait { @include icon-override("stay_current_portrait"); }
.ui-icon-stay-primary-landscape { @include icon-override("stay_primary_landscape"); }
.ui-icon-stay-primary-portrait { @include icon-override("stay_primary_portrait"); }
.ui-icon-stop { @include icon-override("stop"); }
.ui-icon-stop-screen-share { @include icon-override("stop_screen_share"); }
.ui-icon-storage { @include icon-override("storage"); }
.ui-icon-store { @include icon-override("store"); }
.ui-icon-store-mall-directory { @include icon-override("store_mall_directory"); }
.ui-icon-straighten { @include icon-override("straighten"); }
.ui-icon-streetview { @include icon-override("streetview"); }
.ui-icon-strikethrough-s { @include icon-override("strikethrough_s"); }
.ui-icon-style { @include icon-override("style"); }
.ui-icon-subdirectory-arrow-left { @include icon-override("subdirectory_arrow_left"); }
.ui-icon-subdirectory-arrow-right { @include icon-override("subdirectory_arrow_right"); }
.ui-icon-subject { @include icon-override("subject"); }
.ui-icon-subscriptions { @include gtc-override("subscriptions"); }
.ui-icon-subtitles { @include icon-override("subtitles"); }
.ui-icon-subway { @include icon-override("subway"); }
.ui-icon-supervisor-account { @include icon-override("supervisor_account"); }
.ui-icon-surround-sound { @include icon-override("surround_sound"); }
.ui-icon-swap-calls { @include icon-override("swap_calls"); }
.ui-icon-swap-horiz { @include icon-override("swap_horiz"); }
.ui-icon-swap-vert { @include icon-override("swap_vert"); }
.ui-icon-swap-vertical-circle { @include icon-override("swap_vertical_circle"); }
.ui-icon-switch-camera { @include icon-override("switch_camera"); }
.ui-icon-switch-video { @include icon-override("switch_video"); }
.ui-icon-sync { @include icon-override("sync"); }
.ui-icon-sync-disabled { @include icon-override("sync_disabled"); }
.ui-icon-sync-problem { @include icon-override("sync_problem"); }
.ui-icon-system-update { @include icon-override("system_update"); }
.ui-icon-system-update-alt { @include icon-override("system_update_alt"); }
.ui-icon-tab { @include icon-override("tab"); }
.ui-icon-tab-unselected { @include icon-override("tab_unselected"); }
.ui-icon-tablet { @include icon-override("tablet"); }
.ui-icon-tablet-android { @include icon-override("tablet_android"); }
.ui-icon-tablet-mac { @include icon-override("tablet_mac"); }
.ui-icon-tag-faces { @include icon-override("tag_faces"); }
.ui-icon-tap-and-play { @include icon-override("tap_and_play"); }
.ui-icon-terrain { @include icon-override("terrain"); }
.ui-icon-text-fields { @include icon-override("text_fields"); }
.ui-icon-text-format { @include icon-override("text_format"); }
.ui-icon-textsms { @include icon-override("textsms"); }
.ui-icon-texture { @include icon-override("texture"); }
.ui-icon-theaters { @include icon-override("theaters"); }
.ui-icon-thumb-down { @include icon-override("thumb_down"); }
.ui-icon-thumb-up { @include icon-override("thumb_up"); }
.ui-icon-thumbs-up-down { @include icon-override("thumbs_up_down"); }
.ui-icon-time-to-leave { @include icon-override("time_to_leave"); }
.ui-icon-timelapse { @include icon-override("timelapse"); }
.ui-icon-timeline { @include icon-override("timeline"); }
.ui-icon-timer { @include icon-override("timer"); }
.ui-icon-timer-10 { @include icon-override("timer_10"); }
.ui-icon-timer-3 { @include icon-override("timer_3"); }
.ui-icon-timer-off { @include icon-override("timer_off"); }
.ui-icon-title { @include icon-override("title"); }
.ui-icon-toc { @include icon-override("toc"); }
.ui-icon-today { @include icon-override("today"); }
.ui-icon-toll { @include icon-override("toll"); }
.ui-icon-tonality { @include icon-override("tonality"); }
.ui-icon-touch-app { @include icon-override("touch_app"); }
.ui-icon-toys { @include icon-override("toys"); }
.ui-icon-track-changes { @include icon-override("track_changes"); }
.ui-icon-traffic { @include icon-override("traffic"); }
.ui-icon-train { @include icon-override("train"); }
.ui-icon-tram { @include icon-override("tram"); }
.ui-icon-transfer-within-a-station { @include icon-override("transfer_within_a_station"); }
.ui-icon-transform { @include icon-override("transform"); }
.ui-icon-translate { @include icon-override("translate"); }
.ui-icon-trending-down { @include icon-override("trending_down"); }
.ui-icon-trending-flat { @include icon-override("trending_flat"); }
.ui-icon-trending-up { @include icon-override("trending_up"); }
.ui-icon-tune { @include icon-override("tune"); }
.ui-icon-turned-in { @include icon-override("turned_in"); }
.ui-icon-turned-in-not { @include icon-override("turned_in_not"); }
.ui-icon-tv { @include icon-override("tv"); }
.ui-icon-unarchive { @include icon-override("unarchive"); }
.ui-icon-undo { @include icon-override("undo"); }
.ui-icon-unfold-less { @include icon-override("unfold_less"); }
.ui-icon-unfold-more { @include icon-override("unfold_more"); }
.ui-icon-update { @include icon-override("update"); }
.ui-icon-usb { @include icon-override("usb"); }
.ui-icon-verified-user { @include icon-override("verified_user"); }
.ui-icon-vertical-align-bottom { @include icon-override("vertical_align_bottom"); }
.ui-icon-vertical-align-center { @include icon-override("vertical_align_center"); }
.ui-icon-vertical-align-top { @include icon-override("vertical_align_top"); }
.ui-icon-vibration { @include icon-override("vibration"); }
.ui-icon-video-call { @include icon-override("video_call"); }
.ui-icon-video-label { @include icon-override("video_label"); }
.ui-icon-video-library { @include icon-override("video_library"); }
.ui-icon-videocam { @include icon-override("videocam"); }
.ui-icon-videocam-off { @include icon-override("videocam_off"); }
.ui-icon-videogame-asset { @include icon-override("videogame_asset"); }
.ui-icon-view-agenda { @include icon-override("view_agenda"); }
.ui-icon-view-array { @include icon-override("view_array"); }
.ui-icon-view-carousel { @include icon-override("view_carousel"); }
.ui-icon-view-column { @include icon-override("view_column"); }
.ui-icon-view-comfy { @include icon-override("view_comfy"); }
.ui-icon-view-compact { @include icon-override("view_compact"); }
.ui-icon-view-day { @include icon-override("view_day"); }
.ui-icon-view-headline { @include icon-override("view_headline"); }
.ui-icon-view-list { @include icon-override("view_list"); }
.ui-icon-view-module { @include icon-override("view_module"); }
.ui-icon-view-quilt { @include icon-override("view_quilt"); }
.ui-icon-view-stream { @include icon-override("view_stream"); }
.ui-icon-view-week { @include icon-override("view_week"); }
.ui-icon-vignette { @include icon-override("vignette"); }
.ui-icon-visibility { @include icon-override("visibility"); }
.ui-icon-visibility-off { @include icon-override("visibility_off"); }
.ui-icon-voice-chat { @include icon-override("voice_chat"); }
.ui-icon-voicemail { @include icon-override("voicemail"); }
.ui-icon-volume-down { @include icon-override("volume_down"); }
.ui-icon-volume-mute { @include icon-override("volume_mute"); }
.ui-icon-volume-off { @include icon-override("volume_off"); }
.ui-icon-volume-up { @include icon-override("volume_up"); }
.ui-icon-vpn-key { @include icon-override("vpn_key"); }
.ui-icon-vpn-lock { @include icon-override("vpn_lock"); }
.ui-icon-wallpaper { @include icon-override("wallpaper"); }
.ui-icon-warning { @include gtc-override("warning"); }
.ui-icon-watch { @include icon-override("watch"); }
.ui-icon-watch-later { @include icon-override("watch_later"); }
.ui-icon-wb-auto { @include icon-override("wb_auto"); }
.ui-icon-wb-cloudy { @include icon-override("wb_cloudy"); }
.ui-icon-wb-incandescent { @include icon-override("wb_incandescent"); }
.ui-icon-wb-iridescent { @include icon-override("wb_iridescent"); }
.ui-icon-wb-sunny { @include icon-override("wb_sunny"); }
.ui-icon-wc { @include icon-override("wc"); }
.ui-icon-web { @include icon-override("web"); }
.ui-icon-web-asset { @include icon-override("web_asset"); }
.ui-icon-weekend { @include icon-override("weekend"); }
.ui-icon-whatshot { @include icon-override("whatshot"); }
.ui-icon-widgets { @include icon-override("widgets"); }
.ui-icon-wifi { @include icon-override("wifi"); }
.ui-icon-wifi-lock { @include icon-override("wifi_lock"); }
.ui-icon-wifi-tethering { @include icon-override("wifi_tethering"); }
.ui-icon-work { @include icon-override("work"); }
.ui-icon-wrap-text { @include icon-override("wrap_text"); }
.ui-icon-youtube-searched-for { @include icon-override("youtube_searched_for"); }
.ui-icon-zoom-in { @include icon-override("zoom_in"); }
.ui-icon-zoom-out { @include icon-override("zoom_out"); }
.ui-icon-zoom-out-map { @include icon-override("zoom_out_map"); }

.material-icons, .fa {
  font-family: 'Material Icons', FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  position: relative;
  top: 6px;
  margin-top: -$lineHeight;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.approve {
	border-radius: 100px;
    padding: 0px 4px;
    background: red;
    position: absolute;
    right: calc(50% - 40px);
    top: 10px;
    font-size: 10px;
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
}
