// For Wookie Demo
.nohover:hover {
    background: transparent;
}

.layout-main {
    padding: 74px 0px 0px 0px !important;

    .wookie-header {
        @include gradient(-75deg, $zcBlue, $zcRoyal);
        padding: 40px;
        color: $zcWhite;

        h1 {
            @include zf-headline();
        }

        h2 {
            @include zf-display2();
            font-weight: 400;
            color: $zcWhite;
        }

        p {
            @include zf-primary-white();
        }

        a {
            color: $zcWhite;
            text-decoration: underline;
        }

        i {
            position: relative;
            top: 6px;
        }
    }

    .ui-g {
        margin: 40px;

        .ui-g {
            margin: 0px;
        }

        & > div {
            p {
                padding-bottom: 2em;
                margin-bottom: 2em;
            }

            pre {
                font-family: monospace;
                background-color: $zcSmoke;
                background-image: linear-gradient(transparent 50%, $zcGhost 0);
                background-size: 3em 3em;
                background-origin: content-box;
                color: $zcBlack;
                padding: 0;
                font-size: 14px;
                border-radius: 3px;
                overflow: auto;
                position: relative;
                padding: 20px;
                box-shadow: -1px 0 0 0 #dae8ef, 0 0 0 1px #dfdfdf;
                border-left: 10px solid $zcDodger;
                text-align: left;
                white-space: pre;
                word-spacing: normal;
                word-break: normal;
                word-wrap: normal;
                line-height: 1.5;
            }
        }
    }
}

h1.wookie {
    font-size: 1.5em;
    font-weight: 400;
    color: $zcBlue;
}

.open-new::after {
    content: "open_in_new";
    font-family: 'Material Icons';
    display: inline-block;
    position: relative;
    top: 2px;
    left: 4px;
}

.nav-link {
    padding: 12px 16px 12px 12px;
    display: inline-block;
    @include transition(all 0.3s);

    &:hover {
        background: $zcBlack;
    }

    &:last-child {
        margin-right: 24px;
    }

    > a {
        color: $zcDodger;
    }
}

@media only screen and (max-width: 900px) {
    .layout-container .topbar {
        height: auto;
    }
}

.ui-button-icon-only {
    margin: 0;
    padding: 0 16px;
}

// Form Input Mods

.ui-multiselect-outline {

    .ui-dropdown {
        border: 1px solid $zcGray;
        padding: 10px 16px;

        &.ui-state-focus {
            border-bottom: 1px solid $zcGray;
        }

        .ui-dropdown-trigger {
            margin-top: 10px;
            margin-right: 8px;
        }
    }
}

p-radiobutton, p-checkbox {
    &:not(.ng-valid)
        label {
            opacity: .3;
        }
}

p-radiobutton {
    .fa-circle::before {
        content: "" !important;
    }
}

.ui-inputgroup {
    width: calc(100% - 30px);

    label, input, .ui-message {
        margin-left: 30px !important;
    }

    i {
        color: $zcGray;
        float: left;
        position: relative;
        top: -1em;
    }

    &:focus {
        i {
            color: $zcBlue;
        }
    }
}

.ui-inputswitch-label {
    @include zf-primary();
    margin-left: .25em;
    position: relative;
    top: -2px;
}

.slider-with-label {
    display: inline-block;
    width: calc(100% - 48px);
}

.slider-label {
    display: inline-block;
    float: right;
    text-align: center;
    width: 36px !important;
}

.ui-togglebutton {
    .ui-button-icon-left {
        color: $zcBlack !important;
    }

    &.ui-state-active {
        .ui-button-icon-left {
            color: $zcWhite !important;
        }
    }
}

.ui-selectbutton {
    .ui-button {
        background: $zcWhite;
        color: $zcBlack;

        &:hover {
            background: $zcGhost !important;
        }
    }

    .ui-state-active {
        background: $zcBlue !important;
        color: $zcWhite !important;

        &:hover {
            background: $zcRoyal !important;
        }
    }
}

.ui-chips {
    .ui-state-disabled {
        border-bottom: 0px !important;
        opacity: 1;

        .ui-chips-token {
            padding-right: .75em !important;
        }
    }
}

.ui-chips, .ui-chips-dark {
    > ul.ui-inputtext {
        padding: 2px 2px 1px 2px;

        i {
            float: left;
            color: $zcGhost;
            font-size: 1.25em;
            margin: 2px .25em 0px 0px;
        }
    }
}

.ui-overlaypanel {
    @include border-radius(0);

    .ui-overlaypanel-content {
        padding: 0;
        min-width: 200px;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                cursor: pointer;
                padding: 1em;
                transition: background 0.3s;

                &:hover {
                    background: $zcPowder;
                }
            }

        }
    }
}

p-autocomplete {
    .ui-autocomplete-loader {
        line-height: .75 !important;
        margin-top: -.75em;
        cursor: default;
        animation: pulse-ani 1.5s infinite linear;
    }
}

@keyframes pulse-ani {
    0% {
        color: $zcBlack;
        transform:rotate(0deg);
    }
    50% {
        color: $zcBlue;
        transform:rotate(180deg);
    }
    100% {
        color: $zcBlack;
        transform:rotate(360deg);
    }
}





// Filter Drawer

.filter-drawer-container {
    width: 320px;
    padding: 0;
    background: $zcWhite;
    margin-bottom: 100px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;

    label {
        line-height: 1em;
    }

    .filter-drawer-header {
        padding: 16px 0 16px 20px;
        background: $zcBlue;
        color: $zcWhite;
        font-size: 18px;
        height: 36px;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .filter-header-name {
            margin-right: auto;
        }

        .filter-drawer-header-close {
            font-size: 26px;
            box-shadow: none;
        }
    }

    .ui-g-12 {
        padding: 20px;

        .zf-label {
            @include zf-label();
        }
    }

    .filter-drawer-content {
        margin-bottom: auto;
        padding-bottom: 20px;
        overflow-y: auto;
        flex: auto;
        width: calc(100% - 40px);
        transition: width 0.3s;

        label.ui-helper {
            @include zf-label();
        }

        & > form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            & > * {
                @extend .filter-item;
            }

            & .ui-dropdown, .ui-multiselect {
                width: 100% !important;
            }
        }

        span.manage-filters {
            font-size: 12px;
            float: right;
            margin-bottom: 2px;
        }

        .customDateRangeSelect {
            margin: 4px 0px 4px 16px;

            .ui-datepicker {
                position: relative;
                left: -56px !important;
                top: auto !important;
            }

            span.ui-calendar {
                .ui-button {
                    display: none;
                }
            }
        }

        .ui-multiselect {
            .ui-multiselect-trigger {
                top: -.5em;
            }
        }
    }

    .filter-drawer-action {
        background: #FFF;
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        flex-shrink: 0;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.22);
        -webkit-box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.22);
        padding: 20px 20px 50px 20px;
        height: 120px;

        & > * {
            margin-bottom: 10px;
            user-select: none;
            flex-shrink: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .button-group {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;

            button {
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
            }
        }

        .secondary {
            margin: 0 5px 0 0;
        }
    }
}

// Dialogs

.ui-dialog {
    border: 0 !important;

    .ui-dialog-content {
        padding: 0 2em 1em !important;

        footer {
            margin-top: 1em;

            .ui-dialog-buttonpane {
                border: 0;

                .primary, .secondary {
                    min-width: 120px;
                    @include ui-shadow-1();
                }

                .primary {
                    background: $zcBlue;
                    color: $zcWhite;

                    &:hover {
                        background: $zcRoyal;
                    }
                }

            }
        }
    }
}

.ui-modal {
    .ui-dialog {
        .ui-dialog-titlebar {
            background: $zcBlue;
            color: $zcWhite;

            .ui-dialog-titlebar-icon {
                &:hover {
                    background: $zcRoyal;
                }

                .fa {
                    color: $zcWhite;
                }
            }
        }

        .ui-dialog-content {
            padding: 1em 1.5em !important;
        }
    }
}

.modal-output {
    .ui-dialog-content {
        overflow: visible;

        & > div, form > div {
            margin: 20px 0;

            .ui-calendar {
                .ui-inputtext {
                    width: 200px;
                }

                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        p {
            display: inline-block;
            margin-right: 1em !important;
        }

        p-radiobutton {
            margin-right: 1em;
        }

        .export-modal-input-section {
            margin: 20px 0;

            .ui-inputgroup {
                width: 100%;

                & > label {
                    display: none;
                }

                & > input {
                    margin-left: 0px !important;
                    width: 100%;
                }

                .maxlength-indicator {
                    float: right;
                    border: none;
                }
            }
        }

        .ui-dialog-buttonpane {
            margin-top: 40px;
        }
    }
}

// Mega Menu cleanups

.ui-megamenu {
    text-align: center;

    // empty menu display weird div
    & > .ui-menu-list > :not(.ui-menu-parent) > .ui-megamenu-panel {
        display: none !important;
    }

    .ui-menuitem {
        .ui-submenu-icon {
            display: none !important;
        }
        .ui-menuitem-link {
            padding: 1.5em 1em !important;
            border-bottom: 4px solid $zcWhite !important;
            @include transition(all 0.3s);

            .ui-menuitem-text {
                font-size: 16px;
                color: $zcBlue;
            }

            .ui-menuitem-icon {
                display: none;
            }
        }

        .ui-megamenu-panel {
            text-align: left;

            .ui-menuitem-link {
                padding: .25em 1em !important;
                border-bottom: 0px !important;

                &:hover {
                    background: $zcWhite !important;
                    border-bottom: 0px !important;

                    .ui-menuitem-text {
                        text-decoration: underline;
                    }
                }

                .ui-menuitem-text {
                    font-size: 14px;
                    color: $zcBlue;
                }
            }
        }
    }

    .ui-menuitem-active {
        & > .ui-megamenu-panel {
            display: flex !important;
            justify-content: center;
            justify-items: center;
            box-shadow: none;
            border: none;
            padding-top: 0 !important;
            position: fixed;
            right: 0 !important;
            left: 0 !important;
            margin-right: auto;
            margin-left: auto;

            & > .ui-g {
                background-color: $zcWhite;
                border: 1px solid $zcSmoke;
                @extend .md-whiteframe-2dp;
            }
        }

        .ui-menuitem-link {
            border-bottom: 4px solid $zcBlue !important;
        }
    }
}

.ui-megamenu:not(.ui-megamenu-vertical) {
    .ui-megamenu-panel {
        margin-top: -1px;
        width: 1%; // ramdom number. Just need width for the "left 0 right 0 margin auto" to work
        align-items: center;
        place-content: center;
        background: none;
    }
}

// ie hack, this will still leave the problem hover to the side not close the panel
// The "left 0 right 0 margin auto" does not work in ie11
// TODO find a solution that work with ie11 or redo megamenu
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .ui-megamenu:not(.ui-megamenu-vertical) {
        .ui-megamenu-panel {
            width: auto;
        }
    }
}

// Zonar Mega Menu component specific
zonar-mega-menu {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: $zcWhite;

    &.mega-menu-compact {
        justify-content: left;
    }

    & > .menu-container {
        display: flex;
        align-items: center;
        align-content: center;
    }

    & .ui-menu {
        border: none;
    }

    & .ui-button {
        font-size: 1.5em;
        background-color: $zcWhite;
        box-shadow: none;

        .fa {
            color: $zcBlue;
        }
        &:hover {
            background-color: $hoverBgColor !important;
        }
    }
}

.menu-container {

    .mega-menu-item {
        width: 68px !important;
        height: 68px !important;
    }

    .ui-menu {
        .ui-menuitem {
            .ui-menuitem-link {
                min-height: 0px !important;

                &:hover {
                    background: $hoverBgColor !important;

                    .ui-menuitem-text {
                        text-decoration: none;
                    }
                }

                .ui-menuitem-icon {
                    height: 0px;
                    width: 0px;
                }
            }
        }
    }
}

.ui-overlaypanel .ui-overlaypanel-content {
    min-width: auto;
}

.ui-megamenu-vertical {
    overflow-y: auto;

    .ui-megamenu-panel.ui-menu-child {
        width: 200px !important;
        margin: 0px !important;
        padding: 0px !important;
        top: 60px !important;
        left: 172px !important;
    }

    .ui-menuitem {
        padding: 0px !important;

        .ui-megamenu-panel > .ui-g {
            flex-wrap: wrap;
            overflow-y: auto;
            max-width: 200px;
            max-height: calc(100vh - 80px);

            .ui-g-1, .ui-g-2, .ui-g-3, .ui-g-4, .ui-g-5, .ui-g-6, .ui-g-7, .ui-g-8, .ui-g-9, .ui-g-10, .ui-g-11, .ui-g-12 {
                width: 100% !important;
            }
        }

        .ui-menuitem-link {
            border-bottom: 0px !important;
        }
    }

    .ui-menuitem-active {
        .ui-menuitem > .ui-menuitem-link {
            padding: .75em 1em !important;
        }
    }
}

// Subnav updates

.zonar-subnav {
    .report-action {
        font-size: 18px !important;
        text-transform: none !important;
    }

    .ui-button-icon-only {
        width: 56px !important;

        .ui-button-icon-left {
            margin-left: -13px!important;
        }
    }
}

.favorites-list {
    height: 100%;

    .favorites-list-button {
        height: 100%;
        font-size: 1.4em;

        .favorites-list-icon {
            padding-top: 19px;
        }
    }
}

.favorites-list-container {
    .manage {
        list-style-position: inside;
        border-top: 1px solid $zcGray;

        .notifications-entry-display {
            color: $zcBlue !important;
        }
    }
}
