@charset "UTF-8";

body {
    .ui-picklist {
        .ui-picklist-caption {
            font-size: $inputHeaderFontSize;
            padding: $inputHeaderPadding;
        }
        
        .ui-picklist-list {
            padding: 0;
        }
        
        li.ui-picklist-item {
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            margin: 0;
            @include border-radius(0);
            color: $textColor;
            
            &:not(.ui-state-highlight):hover {
                @include hover-element();
            }
            
            &.ui-state-highlight {
                background-color: $primaryColor;
                color: $zcWhite;
            }
        }
        
        .ui-picklist-buttons {
            width: 3em;
            
            .ui-button {
                &.ui-button-icon-only {
                    width: 2.5em;
                    margin-right: 0;
                    display: inline-block;
                    margin-bottom: .5em;
                }
            }        
        }
        
        .ui-picklist-buttons-cell {
            text-align: center;
        }
                
        &.ui-picklist-responsive {
            .ui-picklist-buttons {
                .ui-button {
                    &.ui-button-icon-only {
                        margin: 0 auto;
                        display: block;
                        margin-bottom: .5em;
                    }
                }
            }
            
            .ui-picklist-list {
                .ui-picklist-item {
                    .ui-chkbox {
                        margin-right: .5em;
                        vertical-align: top;
                    }
                    
                    .ui-chkbox,.ui-chkbox * {
                        box-sizing: content-box;
                    }
                }
            }
        }
    }
    
    .ui-orderlist {
        .ui-orderlist-caption {
            font-size: $inputHeaderFontSize;
            padding: $inputHeaderPadding;
            box-sizing: border-box;
        }
        
        .ui-orderlist-list {
            padding: 0;
            box-sizing: border-box;
            
            li.ui-orderlist-item {
                font-size: $inputOptionFontSize;
                padding: $inputOptionPadding;
                margin: 0;
                @include border-radius(0);
                color: $textColor;

                &:not(.ui-state-highlight):hover {
                    @include hover-element();
                }
                
                &.ui-state-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }
        }
        
        .ui-orderlist-controls {
            width: 3.75em;
            text-align: center;
            
            .ui-button {
                &.ui-button-icon-only {
                    width: 2.5em;
                    margin-bottom: .5em;
                    margin-right: 0;
                    display: inline-block;
                }
            }
        }
    }
    
    .ui-paginator {
        background-color: $primaryDarkColor;
        padding: $headerPadding;
        border: 0;
        
        > a {
            box-sizing: border-box;
            color: $zcSmoke;
            
            .fa {
                display: none;
            }
            
            &:not(.ui-state-disabled):not(.ui-state-active):hover {
                background-color: $primaryLightColor;
                @include border-radius(50%);
                @include transition(background-color .3s);
            }
        }
        
        .ui-paginator-next {
            padding: 0;
            vertical-align: middle;
            @include material-icon("navigate_next");
        }

        .ui-paginator-last {
            padding: 0;
            vertical-align: middle;
            @include gtc-icon("last_page");
        }

        .ui-paginator-prev {
            padding: 0;
            vertical-align: middle;
            @include material-icon("navigate_before");
        }

        .ui-paginator-first {
            padding: 0;
            vertical-align: middle;
            @include gtc-icon("first_page");
        }
        
        .ui-paginator-pages {
            vertical-align: middle;
            margin: 0 .375em 0 .375em;
            
            a {
                padding: 0;
                color: $zcSmoke;
                min-width: 1.5em;
                min-height: 1.5em;
                line-height: 1.5em;
                @include border-radius(50%);
                @include transition(background-color .3s);
                
                &:not(.ui-state-active):hover {
                    background-color: $primaryLightColor;
                }        
                
                &.ui-state-active {
                    background-color: $primaryColor;
                    color: $zcWhite;
                }
            }
        }
    }
    
    .ui-datagrid {
        .ui-datagrid-header {
            padding: $headerPadding;
        }
        
        .ui-panel {
            .ui-panel-titlebar {
                background-color: #ffffff;
                color: $textColor;
                border-color: $dividerColor;
            }
        }
    }
    
    .ui-datalist {
        .ui-datalist-header {
            padding: $headerPadding;
        }
    }
    
    .ui-datatable {
        .ui-datatable-header {
            height: 0px;
            font-size: 0px;
            border: 0px;
        }

        .ui-datatable-footer {
            padding: $headerPadding;
            
            .ui-inputtext {
                color: #ffffff;
                
                &:focus {
                    border-color: #ffffff;
                }
            }  
        }
        
        .ui-paginator {
            padding: $headerPadding;
        }
        
        .ui-datatable-thead {
            > tr {
                 border-color: $dividerLightColor;

                 th {
                    padding: .625em .875em;
                    border: 0;
                    background-color: $zcSteel;
                    
                    .ui-column-title {
                        @include zf-header();
                        display: inline-block;
                        vertical-align: middle;
                    }
                    
                    .ui-sortable-column-icon {
                        vertical-align: middle;
                        color: $textSecondaryColor;
                    }
                    
                    &.ui-state-active,&.ui-state-highlight {
                        background-color: $zcGray;

                        .ui-column-title {
                            color: $zcGhost;
                        }
                        
                        .fa {
                            color: $zcGhost;
                        }
                        
                        .ui-inputtext {
                            color: $zcGhost;
                        }
                    }
                    
                    &.ui-selection-column {
                        .ui-chkbox-box {
                            box-sizing: content-box;
                        }
                    }
                }
            }
        }
        
        tfoot {
            th {
                padding: .625em .875em;
                background-color: #ffffff;
            }
        }
        
        .ui-datatable-data { 

            border: 0px;

            tr.ui-datatable-even {
                background-color: $zcGhost;
                                
                &.ui-state-highlight {
                    background-color: $primaryColor;
                    color: $zcWhite;
                }
            }
                               
            tr {
                border: 0;
                @include transition(all 0.3s);

                td {
                    padding: .625em .875em;
                    border: 0;
                    
                    .ui-row-toggler  {
                        display: inherit;
                    }
                    
                    &.ui-state-highlight {
                        .ui-inputtext {
                            color: #ffffff;
                        }
                    }
                    
                    &.ui-state-error {
                        background-color: #e62a10;
                        border-color: #e62a10;
                        color: #ffffff;
                    }
                    
                    input.ui-cell-editor {
                        padding: 2px 2px 1px 2px;
                        font-size: $inputFontSize;
                    }
                    
                    &.ui-selection-column {
                        .ui-chkbox-box, .ui-radiobutton-box {
                            box-sizing: content-box;
                        }
                    }
                }
                
                &.ui-state-highlight{
                    background-color: $primaryColor;
                    color: $zcWhite;
                }
                
                .ui-cell-editor-input {
                    input {
                        color: $accentTextColor;
                    }
                }
            }
            
            tr.ui-state-error {
                background-color: #e62a10;
                border-color: #e62a10;
                color: #ffffff;
                
                .ui-inputtext,
                .ui-inputtext.ui-state-error {
                    border-color: #ffffff;
                }
            }
            
            tr.ui-state-highlight {
                td.ui-selection-column {
                    .ui-radiobutton-box {
                        border-color: #ffffff;
                        
                        .ui-radiobutton-icon {
                            background-color: #ffffff;
                        }
                    }
                    
                    .ui-chkbox-box {
                        border-color: #ffffff;
                        background-color: #ffffff;
                        
                        .ui-chkbox-icon {
                            color: $textSecondaryColor;
                        }
                    }
                }
                
                .ui-inputtext {
                    color: #ffffff;
                    border-color: #ffffff;
                    
                    &:focus {
                        border-color: #ffffff;
                    }
                }
            }
            
            tr.ui-rowgroup-header {
                td {
                    a {
                        height: 1.5em;
                        vertical-align: top;
                        display: inline-block;
                    }
                }
            }

            &.ui-datatable-hoverable-rows {
                > tr.ui-widget-content:not(.ui-state-highlight):hover {
                    cursor: pointer;
                    background-color: $zcPowder;
                    @include transition(all 0.3s);
                }
            }
        }        
        
        &.ui-datatable-scrollable {
            .ui-datatable-scrollable-header,  .ui-datatable-scrollable-footer {
                border: 0 none;
                background-color: transparent;
                
                .ui-datatable-data {
                    td {
                        color: $textColor;
                    }
                }
            }
            
            thead {
                tr {
                    th {
                        color: $textColor;
                        font-size: $fontSize;
                    }
                }
            }
            
            tfoot {
                tr {
                    td {
                        color: $textColor;
                        font-size: $fontSize;
                    }
                }
            }
        }
    }
    
    .ui-carousel {
        padding: 0;
                
        .ui-carousel-header{
            padding: $headerPadding;
            font-size: $headerFontSize;
            overflow: visible;
            
            .ui-carousel-header-title {
                overflow: visible;
            }
            
            .fa {
                color: #ffffff;
            }
            
            .ui-carousel-button {
                margin: -1px 0 0 0;
            }
            
            .ui-carousel-page-links {
                margin: -1px 0 0 0;
            }
        }
    }
    
    .ui-tree {
        padding: $contentPadding / 2;
        
        .ui-treenode-children {
            padding-left: 1.75em;
        }
        
        .ui-treenode-content {
            
            .ui-chkbox {
                margin-right: .5em; 
                
                .fa {
                    color: #757575;
                }   
            }
            
            .ui-tree-toggler {
                vertical-align: middle;
                margin: 0 0 0 .25em;
            }
            
            .ui-treenode-icon  {
                vertical-align: middle;
                margin: 0 .25em;
            }
            
            .ui-chkbox {
                margin: 0 .25em;
            }
            
            .ui-treenode-label {
                margin: 0;
                vertical-align: middle;                
            }

            &.ui-treenode-selectable {
                .ui-treenode-label:not(.ui-state-highlight):hover {
                    @include hover-element();
                }
            }
        }
                
        &.ui-tree-horizontal {
            padding-left: 0;
            padding-right: 0;
            
            .ui-treenode-content {
                background-color: #ffffff;
                border: 1px solid $dividerColor;
                
                .ui-tree-toggler {
                    vertical-align: top;
                }
                
                .ui-treenode-icon  {
                    vertical-align: top;
                    margin-right: .25em;
                }

                &.ui-treenode-selectable:hover {
                    @include hover-element();
                }
                
                &.ui-state-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }
        }
    }
    
    .ui-tree-draghelper {
        border: 1px solid $primaryColor;
    }
    
    .fc {
        .fc-button-group {            
            .ui-icon-circle-triangle-e {
                @include material-icon("play_circle_outline");
                margin-top: .25em;
            }
            
            .ui-icon-circle-triangle-w {
                @include material-icon("play_circle_outline"); @include rotate(180deg);
                margin-top: .25em;
            }
            
            .ui-state-active {
                background-color: $accentColor;
            }
        }
        
        .fc-event {
            background-color: $primaryLightColor;
            color: #ffffff;
        }
    
        table {
            box-sizing: border-box;
        }
    }
    
    .ui-treetable {
        .ui-treetable-header {
            padding: $headerPadding;
            font-size: $headerFontSize;
        }
        
        thead {
            tr {
                border-bottom: 1px solid $dividerColor;
            
                th {
                    background-color: #ffffff;
                    padding: .625em .875em;
                    border: 0 none;
                    
                    .fa {
                        color: $textSecondaryColor;
                    }
                    
                    &:first-child {
                        border-left: 1px solid $dividerColor;
                    }
                    
                    &:last-child {
                        border-right: 1px solid $dividerColor;
                    }
                    
                    .ui-sortable-column-icon {
                        vertical-align: middle;
                        margin: -.25em 0 0 0;
                    }
                    
                    &.ui-state-active {
                        background-color: $accentColor;
                        color: $accentTextColor;
                        
                        .fa {
                            color: $accentTextColor;
                        }
                    }
                    
                    .ui-column-resizer {
                        @include material-icon("code");
                        font-size: 1em;
                        color: $textSecondaryColor;
                    }
                }    
            }
        }
        
        tfoot {
            td {
                border: 0 none;
                padding: .625em .875em;
            }
        }
        
        tbody {                        
            .ui-treetable-row {
                background-color: #ffffff;
                
                td {
                    border: 0 none;
                    padding: .625em .875em;
                    
                    .ui-treetable-toggler {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 .167em;
                        float: none;
                    }
                    
                    .ui-chkbox {
                        margin-right: .5em;
                    }
                    
                    &.ui-treetable-child-table-container {
                        padding: 0;
                    }
                }

                &.ui-treetable-row-selectable:not(.ui-state-highlight):hover {
                    @include hover-element();
                }
                
                &.ui-state-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                    
                    .ui-chkbox {
                        .ui-chkbox-box {
                            border-color: $accentTextColor;
                        }
                    }
                }
            }
        }
        
        &.ui-treetable-scrollable {
            .ui-treetable-scrollable-header, .ui-treetable-scrollable-footer {
                background-color: transparent;
                border: 0 none;   
            }
            
            thead {
                th {
                    background-color: #ffffff;
                    color: $textColor;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    body {                
        .ui-picklist {
            &.ui-picklist-responsive {
                .ui-picklist-list-wrapper {
                    margin-bottom: .5em;
                }
                
                .ui-picklist-buttons {
                    padding: .5em 0;
                    
                    .ui-button {
                        &.ui-button-icon-only {
                            display: inline-block;
                            margin-right: .25em;
                            margin-bottom: 0;
                        }
                    }
                    
                    .fa-angle-right {
                        @include icon-override("play_arrow"); @include rotate(90deg);
                    }
                    .fa-angle-double-right {
                        @include icon-override("skip_previous"); @include rotate(-90deg);
                        
                    }
                    .fa-angle-left {
                        @include icon-override("play_arrow"); @include rotate(-90deg);
                    }
                    .fa-angle-double-left {
                        @include icon-override("skip_next"); @include rotate(-90deg);
                    }
                }
            }
        }
        
        .ui-orderlist {
            &.ui-grid-responsive {
                .ui-orderlist-controls {
                    text-align: center;
                    width: auto;
                    
                    .ui-button {
                        margin-right: .25em;  
                    }
                }
            }
        }
    }
}