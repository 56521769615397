@import './mixins';


/* Base numbers to base px off of */
$basePx5: 5px;
$basePx6: 6px;

$mediumDeviceMinWidth: 641px;
$largeDeviceMinWidth: 1025px;

$textColor: #293239;
$textSecondaryColor: #757575;
$fontSize: 14px;
$lineHeight: 1.5em;
$borderRadius: 3px;
$headerFontSize: 1em;
$headerPadding: .625em 1em;
$contentFontSize: 1em;
$contentPaddingTopBottom: .625em;
$contentPaddingRightLeft: 1em;
$contentPadding: $contentPaddingTopBottom $contentPaddingRightLeft;
$inputHeaderFontSize: 1em;
$inputHeaderPadding: .625em 1em;
$inputFontSize: 1em;
$buttonFontSize: 1em;
$inputOptionFontSize: 1em;
$inputOptionPadding: .625em .875em;
$hoverBgColor: #E2E4E6;
$hoverTextColor: #000000;
$dividerColor: #bdbdbd;
$dividerLightColor: #cacaca;
$grayBgColor: #757575;
$iconFontSize: 1.5em;
$invalidInputLabelColor: #EE4C38;
$invalidInputBorderColor: #EE4C38;
$mainHeaderHeight: $lineHeight * 2;

/* Tab Menu */
$tabContainerMargin: 3em;
$tabHighlightBorderWidth: 4px;

/* Compact */
$c-fontSize: 14px;
$c-lineHeight: 18px;

//---- COLOR SETTINGS----
$zcBlack: #293239;
$zcGunmetal: #49555E;
$zcGray: #5B6770;
$zcSlate: #A2AAAF;
$zcSteel: #B9BEC1;
$zcSilver: #D1D3D4;
$zcGhost: #E2E4E6;
$zcSmoke: #F3F4F5;
$zcWhite: #FFFFFF;
$zcRoyal: #0074CD;
$zcBlue: #398CCB;
$zcDodger: #7BBBEC;
$zcPowder: #DEEDFA;
$zcCrit: #EE4C38;
$zcNotify: #E67300;
$zcWarning: #F3AF3A;
$zcPass: #7DBB7A;

/* subnav */
$zSubnavBackgroundColor: $zcGunmetal;
$zSubnavHeight: 56px;

/* filter drawer */
$filter-item-spacing: 20px;

.filter-item {
    margin-bottom: $filter-item-spacing;

    & > label {
        display: block;
        @include zf-label();
    }

    &:last-child {
        margin-bottom: 0;
    }
    .ui-calendar-label {
        float: left;
        line-height: 2;
    }
    .ui-calendar {
        float: right;
        line-height: 2;
        width: 80%;
    }
}

/*Shadow*/
$fuzzy-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

//----WHITEFRAMES----
// from https://github.com/angular/material/blob/72d068593b7f353d32f8308870d2b0d1c7c759c9/src/core/style/variables.scss
$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;
$whiteframe-shadow-1dp: 0 1px 3px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 1px 1px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 2px 1px -1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-2dp: 0 1px 5px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 3px 1px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-3dp: 0 1px 8px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 3px 4px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 3px 3px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-4dp: 0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 1px 1 0 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-5dp: 0 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 5px 8px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 1px 14px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-6dp: 0 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 6px 1 0 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 1px 18px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-7dp: 0 4px 5px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 7px 1 0 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 2px 16px 1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-8dp: 0 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 8px 1 0 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-9dp: 0 5px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 9px 12px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 3px 16px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-10dp: 0 6px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 1 0 14px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 4px 18px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-11dp: 0 6px 7px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 11px 15px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 4px 2 0 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-12dp: 0 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 12px 17px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 5px 22px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-13dp: 0 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 13px 19px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 5px 24px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-14dp: 0 7px 9px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 14px 21px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 5px 26px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-15dp: 0 8px 9px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 15px 22px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 6px 28px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-16dp: 0 8px 1 0 -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 6px 3 0 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-17dp: 0 8px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 17px 26px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 6px 32px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-18dp: 0 9px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 18px 28px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 7px 34px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-19dp: 0 9px 12px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 19px 29px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 7px 36px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-20dp: 0 1 0 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 2 0 31px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 8px 38px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-21dp: 0 1 0 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 21px 33px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 8px 4 0 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-22dp: 0 1 0 14px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 22px 35px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 8px 42px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-23dp: 0 11px 14px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 23px 36px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 9px 44px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-24dp: 0 11px 15px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 24px 38px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0 9px 46px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
