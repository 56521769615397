body {
    .ui-draggable-dragging.ui-state-default {
        padding: $headerPadding !important;
        background-color: $primaryColor;
    }
    
    .jqplot-target {
        font-family: OpenSans,"Roboto","Helvetica Neue",sans-serif;
    }
    
    .ui-progressbar {
        height: .5em;
        background-color: $zcGhost;
        overflow: hidden;
        border: 0 none;
        
        .ui-progressbar-value {
            height: .5em;
        }
        
        .ui-progressbar-label {
            color: #ffffff;
            display: none !important;
        }
    }
    
    .ui-galleria {
        .ui-galleria-nav-prev {
            left: 0;
        }
        
        .ui-galleria-nav-next {
            right: 0;
        }
    }
    
    .ui-inplace {
        .ui-inplace-display {
            @include transition(background-color .3s);
            @include border-radius(3px);
            
            &:hover {
                background-color: $hoverBgColor;
            }
        }
    }
    
    .ui-terminal {
        .ui-terminal-input {
            font-size: $fontSize;
        }
    }
}